import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    NullableBooleanInput,
    useRecordContext, required, SelectInput, useEditContext, FormDataConsumer,
} from 'react-admin';
import {Contents} from "../../custom";
import {EditToolbar} from "../../common/EditToolbar";
import {useParams} from "react-router";
import {LoadingSection} from "../../common/LoadingSection";
import ImageInput from "../../common/inputs/ImageInput";
import {CONTENT_TYPES, STATUS} from "../../common/constants";
import PDFInput from "../../common/inputs/PDFInput";
import VideoAutocompleteInput from "../../common/inputs/VideoAutocompleteInput";

const EditForm = () => {
    const {record} = useEditContext()
    return <SimpleForm toolbar={<EditToolbar/>} defaultValues={record}>
        <TextInput source="title" fullWidth validate={[required()]}/>
        <ImageInput label={'Thumbnail'} source="thumbnail" fullWidth/>
        <SelectInput
            source="type"
            validate={[required()]}
            label={'Type'}
            fullWidth
            disabled={true}
            choices={CONTENT_TYPES}
        />
        <SelectInput
                source="status"
                label={'Status'}
                fullWidth
                validate={[required()]}
                choices={STATUS}
            />
        <FormDataConsumer>
            {
                ({formData, ...rest}) => {
                    if (formData.type === 'LECTURES') {
                        return <>
                            <VideoAutocompleteInput type={'VIDEOS'} fullWidth
                                                    formData={formData}
                                                    source={'video_id'}/>
                            <TextInput source="restriction_max_duration_multiple" type={'number'} fullWidth/>
                            <TextInput source="restriction_max_number_playback" type={'number'} fullWidth/>
                        </>
                    }
                    if (formData.type === 'NOTES') {
                        return <PDFInput fullWidth
                                         formData={formData}
                                         source={'pdf_url'}/>
                    }
                    return <></>
                }
            }
        </FormDataConsumer>
        <NullableBooleanInput source={'is_demo'} fullWidth label={'Is Demo'}/>
        <TextInput source="display_order" type={'number'} fullWidth/>
        <TextInput source="restriction_max_duration_multiple" type={'number'} fullWidth/>
    </SimpleForm>
}
const ContentEdit = () => {
    let {programId, subjectId, contentId, chapterId, topicId} = useParams();


    if (!contentId) {
        return <LoadingSection/>
    }
    return <Edit mutationMode={'pessimistic'} title={<ContentTitle/>} redirect={() => {
        return `programs/${programId}/subjects/${subjectId}/chapters/${chapterId}/topics/${topicId}/contents`
    }} resource={'contents'} id={contentId}>
        <EditForm/>
    </Edit>
};

const ContentTitle = () => {
    const record = useRecordContext<Contents>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.contents.name', {smart_count: 1})} &quot;
            {record.title}&quot;
        </span>
    ) : null;
};

export default ContentEdit;
