import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { usePlayerControls } from "../../../context/PlayerControlsContext";

export default function YoutubePlayer({ url }) {
  const { player, handlePlayerUpdate } = usePlayerControls();
  const [isLandscape, setIsLandscape] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);

  // Detect screen orientation
  useEffect(() => {
    const handleResize = () => {
      setIsLandscape(window.innerWidth > window.innerHeight);
    };

    handleResize(); // Initial detection
    window.addEventListener("resize", handleResize);

    // Listen for orientation changes (Android + supported browsers)
    window.addEventListener("orientationchange", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("orientationchange", handleResize);
    };
  }, []);

  // Dynamic vh calculation for mobile height issues
  useEffect(() => {
    const setVh = () => {
      document.documentElement.style.setProperty("--vh", `${window.innerHeight * 0.01}px`);
    };
    setVh();
    window.addEventListener("resize", setVh);
    return () => window.removeEventListener("resize", setVh);
  }, []);

  // Handle fullscreen and landscape mode
  const handleFullscreen = async () => {
    const playerElement = document.querySelector(".player-container");

    // Request fullscreen
    if (playerElement.requestFullscreen) {
      await playerElement.requestFullscreen();
      setIsFullscreen(true);
    } else if (playerElement.webkitRequestFullscreen) {
      await playerElement.webkitRequestFullscreen(); // For Safari
      setIsFullscreen(true);
    }

    // Try locking orientation (works on Android)
    if (window.screen.orientation && window.screen.orientation.lock) {
      try {
        await window.screen.orientation.lock("landscape");
      } catch (err) {
        console.error("Orientation lock failed:", err);
      }
    }
  };

  // Function to detect orientation and provide instructions for iOS
  const renderOrientationPrompt = () => {
    if (isFullscreen && !isLandscape) {
      return (
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 bg-white p-4 rounded-lg text-center shadow-lg">
          <p>Please rotate your device to landscape mode for the best experience.</p>
        </div>
      );
    }
    return null;
  };

  // Listen for fullscreen changes and check if it's correctly in landscape
  useEffect(() => {
    const handleFullscreenChange = () => {
      if (document.fullscreenElement || document.webkitFullscreenElement) {
        setIsFullscreen(true);
        // Lock orientation to landscape when entering fullscreen
        if (window.screen.orientation && window.screen.orientation.lock) {
          window.screen.orientation.lock("landscape").catch((err) => {
            console.error("Failed to lock orientation:", err);
          });
        }
      } else {
        setIsFullscreen(false);
      }
    };

    document.addEventListener("fullscreenchange", handleFullscreenChange);
    document.addEventListener("webkitfullscreenchange", handleFullscreenChange);

    return () => {
      document.removeEventListener("fullscreenchange", handleFullscreenChange);
      document.removeEventListener("webkitfullscreenchange", handleFullscreenChange);
    };
  }, []);

  return (
    <div
      className={`player-container relative w-full ${
        isLandscape ? "md:h-[80vh]" : "md:h-[80vh]"
      } md:h-screen bg-black`}
      onClick={handleFullscreen} // Trigger fullscreen and auto-rotate on click
    >
      {renderOrientationPrompt()}
      <ReactPlayer
        width="100%"
        height="100%"
        url={url}
        playing={false} // Prevent autoplay
        config={{
          youtube: {
            playerVars: {
              playsinline: 1, // Allow inline playback on mobile
            },
          },
        }}
        onProgress={({ playedSeconds }) => {
          const updateEvent = new CustomEvent("player-timeupdate", {
            detail: { currentTime: parseInt(playedSeconds, 10) },
          });
          document.dispatchEvent(updateEvent);
        }}
        onReady={(readyPlayer) => {
          const internalPlayer = readyPlayer.getInternalPlayer();

          const player = {
            ...internalPlayer,
            play: async () => {
              return new Promise((resolve) => {
                internalPlayer.playVideo();
                resolve(true);
              });
            },
            get paused() {
              return internalPlayer.getPlayerState() !== 1;
            },
            pause: () => {
              internalPlayer.pauseVideo();
            },
            onPlay: (callback) => {
              internalPlayer.addEventListener("onStateChange", (e) => {
                if (e.data === 1) callback();
              });
            },
            onPause: (callback) => {
              internalPlayer.addEventListener("onStateChange", (e) => {
                if (e.data === 2) callback();
              });
            },
            onWaiting: (callback) => {
              internalPlayer.addEventListener("onStateChange", (e) => {
                if (e.data === 3) callback();
              });
            },
          };

          handlePlayerUpdate(player);

          setTimeout(() => {
            const updateEvent = new CustomEvent("player-metaloaded", {
              detail: {
                duration: parseInt(internalPlayer?.getDuration(), 10),
              },
            });
            document.dispatchEvent(updateEvent);
          }, 100);

          window.player = internalPlayer;
        }}
      />
    </div>
  );
}
