import cn from "clsx";
import { useEffect, useState } from "react";
import { X } from "lucide-react";
import { usePlayerSchedule } from "../../context/PlayerScheduleContext";

export default function
    PlayerSidebarWrapper({ }) {
    const { sidebarMode, setSidebarMode } = usePlayerSchedule();
    const rootClassName = cn('h-full bg-white md:h-full transition-all duration-200', {
        'w-0 opacity-0 overflow-hidden h-[0px] hidden': !sidebarMode,
        'w-full max-w-screen md:w-[400px] md:max-w-[400px] lg:w-[550px] lg:max-w-[550px] opacity-100 h-[calc(100vh-100vw)]': sidebarMode,
    })
    const [contentHeight, setContentHeight] = useState(0);
    useEffect(() => {
        setTimeout(() => {
            if (typeof window !== "undefined" && sidebarMode) {
                // @ts-ignore
                setContentHeight(document.getElementById('sidebar-wrapper')?.offsetHeight - document.getElementById('sidebar-title-wrapper')?.offsetHeight);
            } else {
                setContentHeight(0)
            }

        }, 300)
    }, [sidebarMode])

    return (
        <div className={rootClassName} id={'sidebar-wrapper'}>
            <div className={'flex space-x-2 w-full items-center px-4 py-2 border-b'} id={'sidebar-title-wrapper'}>
                <div className={'flex-1'}>
                    {
                        sidebarMode === 'LIVE_CHAT' && 'Live Chat'
                    }
                    {
                        sidebarMode === 'DOCUMENTS' && 'Documents'
                    }
                    {
                        sidebarMode === 'COMMENTS' && 'Comments'
                    }
                </div>
                <div onClick={() => setSidebarMode('')}
                    className={'rounded-full p-1 cursor-pointer hover:bg-gray-100 transition-all duration-200'}>
                    <X className={'w-6 h-6'} />
                </div>
            </div>
            <div style={{ height: `${contentHeight}px` }}>
            </div>
        </div>
    )
}
