import * as React from 'react';
import Box from '@mui/material/Box';

import {
    useTranslate,
    MenuItemLink,
    MenuProps,
    useSidebarState,
} from 'react-admin';

import MenuOptions from "./SidebarOptions";
import {LoadingSection} from "../common/LoadingSection";
import usePermissionMiddleware from "../common/usePermissionMiddleware";
import MultiLevelMenu from "./MultilevelMenu";

const Menu = ({dense = false}: MenuProps) => {

    const translate = useTranslate();
    const [open] = useSidebarState();
    const {isLoading} = usePermissionMiddleware();

    return (
        <Box
            sx={{
                width: open ? 226 : 50,
                marginTop: 1,
                marginBottom: 1,
                transition: theme =>
                    theme.transitions.create('width', {
                        easing: theme.transitions.easing.sharp,
                        duration: theme.transitions.duration.leavingScreen,
                    }),
            }}
        >
            {
                isLoading ? <div style={{padding: '100px 0', width: '100%'}}>
                        <LoadingSection linear={true}/>
                    </div>
                    : MenuOptions.map((subMenuOptions, index) => {
                        return (
                            <div key={index}>
                                {
                                    subMenuOptions.map((option:any) => {
                                        // if (!option.permission || !hasPermission(option.permission)) {
                                        //     return <></>
                                        // }
                                        if(option.children.length > 0) {
                                            return <MultiLevelMenu option={option} />
                                        }
                                        return <MenuItemLink
                                        
                                            key={option.name}
                                            to={option.to}
                                            state={{_scrollToTop: true}}
                                            primaryText={translate(option.name, {
                                                smart_count: 2,
                                            })}
                                            leftIcon={option.icon}
                                            dense={dense}
                                            target={option?.openInNewTab ? '_blank' : '_self'} 
                                        />
                                    })
                                }
                                {/*<Divider/>*/}
                            </div>
                        );
                    })}

        </Box>
    );
};

export default Menu;
