import {
    List,
    Datagrid,
    TextField, ReferenceField, TopToolbar, CreateButton, ExportButton, useRecordContext,
} from 'react-admin';
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { LoadingSection } from "../../common/LoadingSection";
import { NoDataSection } from "../../common/NoDataSection";
import { Ecom_Attribute_Values, Ecom_Attributes, } from "../../custom";
import Button from "@mui/material/Button";
import { Edit } from "@mui/icons-material";

const ListActions = ({
    categoryId,
    attributeId,
    productTemplateId
}: { productTemplateId?: string, attributeId: string, categoryId?: string }) => {
    const navigate = useNavigate();
    return <TopToolbar>
        <CreateButton onClick={(e) => {
            e.preventDefault();
            if (!productTemplateId) {
                navigate(`/ecom_categories/${categoryId}/ecom_attributes/${attributeId}/ecom_attribute_values/create`)
            } else {
            }
        }} />
        <ExportButton />
    </TopToolbar>
};

const EditButton = () => {
    const navigate = useNavigate();
    const { productTemplateId, categoryId } = useParams();
    const record: Ecom_Attribute_Values = useRecordContext<Ecom_Attribute_Values>();
    return <Button startIcon={<Edit />} onClick={(e) => {
        if (!productTemplateId) {
            navigate(`/ecom_categories/${categoryId}/ecom_attributes/${record.attribute_id}/ecom_attribute_values/${record.id}/edit`)
        } else {
        }
    }}>
        Edit
    </Button>
};

const AttributeValueList = () => {
    const { categoryId, attributeId, productTemplateId } = useParams();
    const navigate = useNavigate();
    if (!categoryId && !productTemplateId) {
        return <LoadingSection />
    }
    return (
        <List
            empty={<NoDataSection resource={'ecom_attribute_values'} onCreate={() => {
                if (!productTemplateId) {
                    navigate(`/ecom_categories/${categoryId}/ecom_attributes/${attributeId}/ecom_attribute_values/create`)
                } else {

                }
            }} />}
            actions={
                <ListActions categoryId={categoryId} attributeId={attributeId || ''}
                    productTemplateId={productTemplateId} />
            } filter={
                {
                    attribute_id: attributeId
                }}
            sort={{ field: 'created_at', order: 'DESC' }} resource={'ecom_attribute_values'}>
            <Datagrid>
                <TextField source="name" />
                <TextField source="slug" />
                <ReferenceField reference={'ecom_attributes'} source={'attribute_id'}>
                    <TextField source={'name'} />
                </ReferenceField>
                <FormattedDateTimeField source="created_at" />

                <EditButton />
            </Datagrid>
        </List>
    );
};
export default AttributeValueList;
