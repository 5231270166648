import { twMerge } from "tailwind-merge";
import { clsx } from "clsx";

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}
export function isLectureLive(start_time, end_time) {
    const now = new Date();
    const start = new Date(start_time);
    const end = new Date(end_time);
    return start <= now && now <= end;
}

export function getFullName(user) {
    if (!user) {
      return "";
    }
    const { first_name, last_name } = user;
    return `${first_name} ${last_name || ""}`.trim();
}

