import * as React from "react";
import {
  Edit,
  SimpleForm,
  useTranslate,
  useRecordContext,
  required,
  SelectInput,
  TextInput,
  minValue,
  ArrayInput,
  SimpleFormIterator,
  DateInput,
  ReferenceInput,
  AutocompleteInput,
} from "react-admin";

import { EditToolbar } from "../../common/EditToolbar";
import { SEGMENTATION_MODULE } from "../../common/constants";
import {
  BatchRender,
  ConditionMethodsChoice,
  ConditionTypeChoice,
  operatorChoices,
  ProgramRender,
} from "./SegmentCreate";

const SegmentEdit = () => {
  const [selectedType, setSelectedType] = React.useState<string | null>(null);
  const handleTypeChange = (typeId: string) => {
    const selectedModule = ConditionTypeChoice.find((type) => type.id === typeId);
    setSelectedType(selectedModule ? selectedModule.name : null);
  };

  return (
    <Edit title={<BannerTitle />}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextInput source="name" fullWidth validate={[minValue(0)]} />

        <SelectInput
          source="module"
          label="Module"
          fullWidth
          defaultValue="BATCH"
          validate={[required()]}
          choices={SEGMENTATION_MODULE}
        />

        <ArrayInput source="condition">
          <SimpleFormIterator>
            <SelectInput
              fullWidth
              source="type"
              label="Type"
              choices={ConditionTypeChoice}
              onChange={(event) => handleTypeChange(event.target.value)} 
            />
            <SelectInput
              fullWidth
              source="method"
              label="Method"
              choices={ConditionMethodsChoice}
            />
            {selectedType === "Batch" ? (
              <ReferenceInput
                name="Batch"
                source="value"
                reference="batches"
                fullWidth
              >
                <AutocompleteInput
                  optionText={BatchRender}
                  debounce={500}
                  fullWidth
                />
              </ReferenceInput>
            ) : (
              <ReferenceInput
                name="Program"
                source="value"
                reference="programs"
                fullWidth
              >
                <AutocompleteInput
                  optionText={ProgramRender}
                  debounce={500}
                  fullWidth
                />
              </ReferenceInput>
            )}
            <SelectInput
              source="operator"
              fullWidth
              label="Operator"
              choices={operatorChoices}
            />
          </SimpleFormIterator>
        </ArrayInput>
      </SimpleForm>
    </Edit>
  );
};

const BannerTitle = () => {
  const record = useRecordContext<any>();
  const translate = useTranslate();

  return record ? (
    <span>
      {translate("Segment", { smart_count: 1 })} &quot;
      {record.module}&quot;
    </span>
  ) : null;
};

export default SegmentEdit;
