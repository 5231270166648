import {
    Show,
    TextInput,
    useTranslate,
    useRecordContext,
    required,
    SelectInput,
    ReferenceInput,
    Create,
    TextField,
    ReferenceField,
    Pagination,
    Datagrid,
    ReferenceManyField,
    SimpleForm,
    useRefresh,
    RichTextField,
    useGetList,
    SimpleShowLayout,
    AutocompleteInput,
    DateInput,
    useDataProvider,
    FormDataConsumer,
    TopToolbar
} from 'react-admin';

import ThumbnailField from "../../common/ThumbnailField";
import FullNameField from "../../common/FullNameField";
import FormattedDateTimeField from '../../common/FormattedDateTimeField';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import { BASE_URL } from "../../common/constants";
import { useState } from "react";
import { useQuery } from "react-query";
import { LinearProgress, Typography } from "@mui/material";
import { NoDataSection } from "../../common/NoDataSection";
import { useParams } from "react-router";
import { LoadingSection } from "../../common/LoadingSection";
import { Ecom_Products, Users } from '../../custom';
import { SoftDeleteWithDialogAction } from '../../common/SoftDeleteWithDialogAction';
import { addDays, format } from "date-fns";
import OrderField from '../../common/OrderField';
import { Today } from '@mui/icons-material';

export const ProductCard = ({ compactView, productId }: {
    compactView?: boolean,
    productId?: string
}) => {
    const record = useRecordContext();
    const { data: products, isLoading } = useGetList(
        'ecom_products',
        {
            pagination: { page: 1, perPage: 1 },
            sort: { field: 'created_at', order: 'ASC' },
            filter: productId ? { id: productId } : {
                product_template: {
                    format: 'hasura-raw-query',
                    value: {
                        id: { _eq: productId || record.id }
                    },
                }
            }
        },
    );

    if (isLoading) {
        return <LinearProgress />
    }
    if (!products?.length) {
        return <NoDataSection resource={'ecom_products'} />
    }
    const product = products?.[0];
    if (compactView) {
        return <div>
            <Typography>
                <div style={{ display: 'flex' }}>
                    Amount:&nbsp;<strong><span>₹</span>
                        {product.amount}</strong>
                </div>
            </Typography>
            <Typography>
                <div style={{ display: 'flex' }}>
                    Discount:&nbsp;<strong><span>₹</span>
                        {product.discount || 0}</strong>
                </div>
            </Typography>
            <Typography>
                <div style={{ display: 'flex' }}>
                    Delivery Amount:&nbsp;<strong><span>₹</span>
                        {product.delivery_amount || 0}</strong>
                </div>
            </Typography>
            <Typography>
                <div style={{ display: 'flex' }}>
                    Final Amount:&nbsp;<strong><span>₹</span>
                        {product.amount - (product.discount || 0) + (product.delivery_amount || 0)}</strong>
                </div>
            </Typography>


        </div>
    }
    return <div>
        <div style={{ display: 'flex' }}>
            {<img src={product.images?.[0]} style={{ marginRight: '10px' }} width={200} />}
            <div>
                <Typography>
                    <div style={{ display: 'flex' }}>
                        Product:&nbsp;<strong><span>₹</span>
                            {product.name}</strong>
                    </div>
                </Typography>

                <Typography>
                    <div style={{ display: 'flex' }}>
                        Amount:&nbsp;<strong><span>₹</span>
                            {product.amount}</strong>
                    </div>
                </Typography>
                <Typography>
                    <div style={{ display: 'flex' }}>
                        Available Quantity:&nbsp;<strong><span>₹</span>
                            {product.available_quantity}</strong>
                    </div>
                </Typography>
                <Typography>
                    <div style={{ display: 'flex' }}>
                        Discount:&nbsp;<strong><span>₹</span>
                            {product.discount || 0}</strong>
                    </div>
                </Typography>
                <Typography>
                    <div style={{ display: 'flex' }}>
                        Delivery Amount:&nbsp;<strong><span>₹</span>
                            {product.delivery_amount || 0}</strong>
                    </div>
                </Typography>

                <Typography>
                    <div style={{ display: 'flex' }}>
                        GST Percentage:&nbsp;<strong><span>₹</span>
                            {product.gst_percentage || 0}</strong>
                    </div>
                </Typography>

                <Typography>
                    <div style={{ display: 'flex' }}>
                        Height in Mtr.:&nbsp;<strong><span>₹</span>
                            {product.height_in_mtr || 0}</strong>
                    </div>
                </Typography>

            </div>
        </div>
        {
            <Typography dangerouslySetInnerHTML={{ __html: product.html_description }} />
        }
    </div>
}

ProductCard.defaultProps = {
    source: 'id',
    label: 'Product',
};

// ====================== Start Assign Product ===================================

export const studentProfileRender = (choice: Users) => `${choice.first_name} ${choice.last_name || ''} | ${choice.phone || 'No Phone'} | ${choice.email || 'No Email'}`;
export const filterToQueryStudent = (searchText: string) => ({ phone: `${searchText}%` });

const AssignProduct = () => {
    const [openStudentModal, setOpenStudentModal] = useState(false);
    const refresh = useRefresh();
    return <>
        <Button onClick={() => {
            setOpenStudentModal(true);
        }} startIcon={<Today />}>
            Assign
        </Button>
        <Dialog
            open={openStudentModal}
            onClose={(_refresh: boolean) => {

                setOpenStudentModal(false)
            }}
        >
            <AssignEbookForm onClose={(_refresh: boolean) => {
                if (_refresh) {
                    refresh();
                }
                setOpenStudentModal(false)
            }} />
        </Dialog>

    </>

}

const AssignEbookForm = (props: any) => {
    let { productId, mode } = useParams();
    const dataProvider = useDataProvider();
    const [loading, setLoading] = useState(false)
    const { data: productsData, refetch, error } = useQuery(
        ['products_' + productId, 'getList', { productId }],
        () => dataProvider.getList('ecom_products', {
            filter: {
                id: productId
            },
            pagination: { page: 1, perPage: 10 },
            sort: { field: 'created_at', order: 'ASC' }
        })
    );
    const postSave = (data: any) => {
        if (loading) {
            return;
        }
        setLoading(true);
        fetch(BASE_URL + '/admin/orders/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({ ...data, offer_amount: parseInt(data.offer_amount) }),
        }).then((q) => q.json()).then((d) => {
            console.log(d);
        }).finally(() => {
            setLoading(false);
            props.onClose(true);
        })
    }
    const products = productsData?.data || [];
    if (!productId) {
        return <LoadingSection />
    }
    return <div style={{ width: '500px', maxWidth: '90vw' }}>
        <Create resource={'student_ebookd'} redirect={undefined} mutationOptions={{
            onSuccess: () => {
                if (props.onClose) {
                    props.onClose(true);
                }
            }
        }}>
            <SimpleForm onSubmit={postSave} defaultValues={{ id: productId }}>
                <ReferenceInput name={'Student by phone'} source={'student_id'} reference={'users'}
                    filter={{ role: 'STUDENT' }}>
                    <AutocompleteInput optionText={studentProfileRender} filterToQuery={filterToQueryStudent}
                        debounce={500} fullWidth validate={[required()]} />
                </ReferenceInput>
                <SelectInput
                    source="product_id"
                    label={'Plan'}
                    fullWidth
                    validate={[required()]}
                    choices={products}
                />
                <FormDataConsumer>
                    {({ formData, ...rest }) => {
                        const product = products.find((p: any) => p.id === formData.product_id);
                        if (!product) {
                            return <></>
                        }
                        return <div className={'w-full'}>
                            <DateInput source="ends_on" label="Ends On" fullWidth
                                defaultValue={addDays(new Date(), product?.validity_in_days)}
                                validate={[required()]} />
                            <ProductCard key={product.id} compactView={true} productId={product.id} />
                            <div className={'pt-2'}>
                                <TextInput fullWidth
                                    type={'number'}
                                    key={product.id + 'price'}
                                    defaultValue={product.amount - (product.discount || 0) + (product.delivery_amount || 0)}
                                    source={'offer_amount'} />
                            </div>
                        </div>
                    }}
                </FormDataConsumer>

            </SimpleForm>
        </Create>
    </div>
};

// ====================== End Assign Product ===================================


export const EndsOnTime = (props: any) => {
    const record = useRecordContext<any>();
    const isExpired = new Date(record.ends_on) < new Date() && record.ends_on;
    return record ? (
        <div className={'flex flex-col text-sm space-y-1'} style={{ background: isExpired ? 'red' : 'transparent' }}>
            {
                record.ends_on && <div className={'flex items-center space-x-2'}>
                    <div className={'whitespace-nowrap'}>
                        {format(new Date(record.ends_on), 'dd-MMM-yyyy hh:mm a')}
                    </div>
                </div>
            }
        </div>
    ) : null;
};

EndsOnTime.defaultProps = {
    source: 'ends_on',
    label: 'Ends On',
};

const CustomListActions = () => (
    <>
    </>
);


const ProductShow = () => {
    const [activeTab, setActiveTab] = useState(0);
    const [studentQuery, setStudentQuery] = useState('');

    const handleTabClick = (index: any) => {
        setActiveTab(index);
    };
    let { productId, categoryId } = useParams();
    if (!productId) {
        return <LoadingSection />
    }
    const dataProvider = useDataProvider();
    const { data: ProductData, isLoading, error } = useQuery(
        ["ecom_categories", categoryId],
        () =>
            dataProvider.getOne("ecom_categories", {
                id: categoryId,
            }),
        {
            enabled: !!categoryId,
        }
    );

    const isEbook = ProductData?.data?.name === "E-Books"

    const tabData = [
        {
            label: 'Details',
            content: <div className='py-8'>
                <Show
                    actions={<CustomListActions />}
                    title={<Title />} resource={'ecom_products'} id={productId}>
                    <SimpleShowLayout>
                        <ThumbnailField size={'large'} source="images" />
                        <TextField source="name" fullWidth />
                        <RichTextField source="html_description" />
                        <TextField source="weight_in_grams" fullWidth />
                        <TextField source="width_in_mtr" fullWidth />
                        <TextField source="length_in_mtr" fullWidth />
                        <TextField source="status" fullWidth />
                        <TextField source="mrp" fullWidth />
                        <TextField source="preview_pdf" fullWidth />
                        <TextField source="pdf_url" fullWidth />
                        <TextField source="validity_in_days" fullWidth />
                    </SimpleShowLayout>
                </Show>
            </div>
        },

        {
            label: 'Students',
            content: <div>
                <div className={'w-full p-2 mt-4'}>
                    <input className={'border w-full rounded p-2'} value={studentQuery}
                        onChange={(e) => setStudentQuery(e.target.value)} placeholder={'Search Student'} />
                </div>
                <Show title={<Title />}
                    actions={<CustomListActions />}
                    resource={'ecom_products'} id={productId}>
                    <ReferenceManyField
                        label={'Ebook Students'}
                        reference="student_ebooks"
                        target="product_id"
                        filter={studentQuery ? {
                            user: {
                                format: 'hasura-raw-query',
                                value: {
                                    _or: [
                                        { phone: { _eq: studentQuery } },
                                        { email: { _eq: studentQuery } },
                                        { first_name: { _ilike: `%${studentQuery}%` } },
                                        { last_name: { _ilike: `%${studentQuery}%` } },
                                    ]
                                },
                            }
                        } : {}}
                        sort={{ field: 'created_at', order: 'ASC' }}
                        pagination={<Pagination />}
                    >
                        <Datagrid
                            sx={{
                                width: '100%',
                                '& .column-comment': {
                                    maxWidth: '20em',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                },
                            }}
                            sort={{ field: 'created_at', order: 'ASC' }}
                        >
                            <ReferenceField reference={'users'} source={'student_id'}>
                                <FullNameField />
                            </ReferenceField>
                            <ReferenceField reference={'users'} source={'created_by_id'}>
                                <FullNameField />
                            </ReferenceField>
                            <ReferenceField reference={'ecom_orders'} source={'order_id'}>
                                <OrderField />
                            </ReferenceField>
                            <FormattedDateTimeField source="created_at" />
                            <EndsOnTime source="ends_on" />
                            <SoftDeleteWithDialogAction />
                        </Datagrid>
                    </ReferenceManyField>
                </Show>
            </div>
        },
    ];

    return <>
        <div className="flex justify-end mr-10 pt-5 pb-2">
            {isEbook && <AssignProduct />}
        </div>
        <div className="border rounded-md mb-14">
            <div className="border-collapse flex text-lg flex-row gap-16 px-10 pt-4">
                {tabData
                    .filter((_, index) => isEbook || index === 0) // Show only the first tab if not an eBook
                    .map((tab, index) => (
                        <div
                            key={index}
                            onClick={() => handleTabClick(index)}
                            className={`tab ${index === activeTab ? 'active text-[#4f3cc9] underline decoration-4 underline-offset-[20px]' : ''}`}
                        >
                            {tab.label}
                        </div>
                    ))}
            </div>
            <div className="tab-content">
                {tabData[activeTab]?.content}
            </div>
        </div>


    </>
};

const Title = () => {
    const record = useRecordContext<Ecom_Products>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('', { smart_count: 1 })} &quot;
            {record.name}
        </span>
    ) : null;
};

export default ProductShow;
