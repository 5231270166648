import React, { useState } from 'react';
import {
    List,
    Datagrid,
    TextField,
    TopToolbar,
    ExportButton,
    useRecordContext,
} from 'react-admin';
import FormattedDateTimeField from "../../../common/FormattedDateTimeField";
import { NoDataSection } from "../../../common/NoDataSection";
import { format } from 'date-fns';
import { downloadCSV, jsonToCSV } from '../../../common/CSVExport';
import MonthSelector from '../../../common/MonthSelector';
import { Typography } from '@mui/material';

interface Record {
    [key: string]: any;
}

const myCustomExporter = (records: Record[]) => {
    const data = records.map((record) => {
        for (let r in record) {
            if (record[r] === null) {
                record[r] = '';
            }
            if (typeof record[r] === 'object') {
                let value: string[] = [];
                for (let innerR in record[r]) {
                    if (record[r][innerR] === null) {
                        record[r][innerR] = '';
                    } else {
                        value.push(record[r][innerR]);
                    }
                }
                record[r] = value.join(' / ');
            }
        }
        return {
            ...record,
            created_at: format(new Date(record.created_at), 'dd-MMM-yyyy hh:mm a'),
        };
    });
    const csv = jsonToCSV(data);
    downloadCSV(csv, 'Orders Report');
};

const ReportOrdersList: React.FC = () => {
   const [selectedMonthRange, setSelectedMonthRange] = useState<{ firstDay: any, lastDay: any, month: any } | null>(null);
       const ListActions = () => (
           <TopToolbar className="">
               <MonthSelector monthsToShow={12} setSelectedMonthRange={setSelectedMonthRange} selectedMonthRange={selectedMonthRange} />
               <ExportButton />
           </TopToolbar>
       );

    return (

        <List
            actions={<ListActions />}
            filter={{
                created_at: {
                    format: 'hasura-raw-query',
                    value: {
                        _gt: selectedMonthRange?.firstDay,
                        _lt: selectedMonthRange?.lastDay
                    }
                },
            }}
            resource='report_orders'
            exporter={myCustomExporter}
            empty={<NoDataSection resource="report_orders" />
        }
        >
            <Datagrid>
                <TextField source="item_name" label="Item Name" />
                <TextField source="item_type" />
                <TextField source="amount" />
                <TextField source="discount" />
                <TextField source="order_by" />
                <TextField source="phone" />
                <TextField source="email" />
                {/* <TextField source="address" /> */}
                {/* <CustomerAddressField/> */}

                <TextField source="pincode" />
                <TextField source="order_id" label="Order Id" />
                <TextField source="payment_type" />
                <TextField source="status" />
                <FormattedDateTimeField source="created_at" />
            </Datagrid>
        </List>
    );
};

export default ReportOrdersList;

const CustomerAddressField = () => {
    const record = useRecordContext();
    console.log("record",record?.address)
    return (
        <div>
            <Typography>
                {record?.address?.first_name} {record?.address?.last_name}
            </Typography>
            <Typography>{record?.address?.phone} </Typography>
            <Typography>{record?.address?.email} </Typography>
            <Typography>{record?.address?.address_line} </Typography>
            <Typography>{record?.address?.landmark} </Typography>
            <Typography>{record?.address?.locality},{record?.address?.city},{record?.address?.district} </Typography>
            <Typography>{record?.address?.pincode} </Typography>
            <Typography>{record?.address?.state} </Typography>
            {/*<Typography>*/}
            {/*    {record?.city}, {record?.stateAbbr} {record?.zipcode}*/}
            {/*</Typography>*/}
        </div>
    );
};

