import {Create, TextInput, NullableBooleanInput, SimpleForm, SelectInput, ReferenceInput, minValue,} from 'react-admin';
import {useParams} from "react-router";
import {LoadingSection} from "../../common/LoadingSection";


const AttributeCreate = () => {
    let {categoryId, productTemplateId} = useParams();
    if (!categoryId && !productTemplateId) {
        return <LoadingSection/>
    }
    return <Create resource={'ecom_attributes'} redirect={(e: any, id: any) => {
        return productTemplateId ? `ecom_categories/${categoryId}/ecom_product_templates/${productTemplateId}/ecom_attributes` : `ecom_categories/${categoryId}/ecom_attributes`
    }}>
        <SimpleForm  defaultValues={
            categoryId ? {
                category_id: categoryId,
            } : {
                product_template_id: productTemplateId,
                category_id: categoryId,
            }
        }>
            <TextInput source="name" fullWidth/>
            <TextInput source="slug" fullWidth/>
            <ReferenceInput reference={'ecom_categories'} source={'category_id'}>
                <SelectInput disabled fullWidth optionText="name"/>
            </ReferenceInput>
            {
                productTemplateId ? <ReferenceInput reference={'ecom_product_templates'} source={'product_template_id'}>
                    <SelectInput fullWidth optionText="name"/>
                </ReferenceInput> : <></>
            }
            <NullableBooleanInput source={'is_filterable'} name={'Is Filterable'}/>
            <TextInput source="display_order" type={'number'} fullWidth validate={[minValue(0)]}/>
        </SimpleForm>
    </Create>
};

export default AttributeCreate;
