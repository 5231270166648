import * as React from "react";
import { Box, Chip, useMediaQuery, Theme } from "@mui/material";
import {
  List,
  EditButton,
  Datagrid,
  TextField,
  useRecordContext,
  NumberField,
  Filter,
  TextInput,
  SelectInput,
  ReferenceInput,
  required,
  useRefresh,
  useCreate,
  useNotify,
  Create,
  SimpleForm,
  Toolbar,
  SaveButton,
  FieldProps,
  useDataProvider,
  ShowButton,
} from "react-admin";
import ThumbnailField from "../../common/ThumbnailField";
import SubjectsLinkField from "./SubjectsLinkField";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import TestSeriesesLinkField from "./TestSeriesesLinkField";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import StatusTags from "../../common/StatusTags";
import ContentCounts from "../../common/ContentCounts";
import { CLASSES, EXAMS, LANGUAGES, STATUS } from "../../common/constants";
import { useState } from "react";
import { Programs, Subjects } from "../../custom";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import PDFInput from "../../common/inputs/PDFInput";
import HTMLEditorInput from "../../common/inputs/HTMLEditorInput";
import { CurrencyRupee } from "@mui/icons-material";
import { useQuery } from "react-query";
import { ProductTemplateFormModal } from "../subjects/SubjectList";

const ClassTextField = ({ source }: { source: string }) => {
  const record = useRecordContext();
  return (
    <span>
      Class: <strong>{record && record[source]?.class}</strong>, Exam:{" "}
      <strong>{record && record[source]?.exam}</strong>, Language:{" "}
      <strong>{record && record[source]?.language}</strong>
    </span>
  );
};

const ListFilter = (props: any) => (
  <Filter {...props}>
    <TextInput label="Search" source="name" alwaysOn />
    <SelectInput source="status" label={"Status"} choices={STATUS} alwaysOn />
  </Filter>
);

const ProductTemplateField = (props: FieldProps<Programs>) => {
  const dataProvider = useDataProvider();
  const record = useRecordContext<Programs>();
  const {
    data: productsData,
    isLoading,
    error: resultError,
  } = useQuery(
    ["products_subject_" + record.id, "getList", { program_id: record.id }],
    () =>
      dataProvider.getList("ecom_products", {
        filter: {
          product_template: {
            format: "hasura-raw-query",
            value: {
              program_id: {
                _eq: record?.id,
              },
            },
          },
        },
        pagination: { page: 1, perPage: 20 },
        sort: { field: "order_by", order: "DESC" },
      })
  );
  const products = productsData?.data;
  if (isLoading) {
    return <div>...</div>;
  }
  if (products?.length) {
    return (
      <div
        className={
          "min-w-[90px] text-center px-2 py-0.5 rounded-md bg-blue-500 text-white no-wrap text-xs"
        }
      >
        Products ({products.length})
      </div>
    );
  }
  return <ProductTemplateFormModal program_id={record.id} />;
};

ProductTemplateField.defaultProps = {
  label: "Product Template",
};
const ProgramNameField = (props: FieldProps<Programs>) => {
  const record = useRecordContext<Programs>();
  const notify = useNotify();
  return (
    <div className={"flex space-x-2 items-center"}>
      <div className={"flex flex-col items-start"}>
        <div className={"font-medium"}>{record.name}</div>
        <ProductTemplateField />
      </div>
    </div>
  );
};

const ProgramList = () => {
  return (
    <List
      filters={<ListFilter />}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <Datagrid>
        <ProgramNameField source="name" />
        <StatusTags source="status" label={"Status"} />
        <NumberField source="display_order" />
        <ClassTextField source="config" />
        <ContentCounts />
        <FormattedDateTimeField source="created_at" />
        <TestSeriesesLinkField />
        <SubjectsLinkField />
        <ShowButton />
        <EditButton />
        <DeleteWithDialogAction />
      </Datagrid>
    </List>
  );
};
export default ProgramList;
