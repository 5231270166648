import React, { createContext, useCallback, useContext, useState } from 'react';

const PlayerControlsContext = createContext({
    player: null,
    paused: true,
    fullscreen: true,
    metaLoaded: true,
    loading: false,
    initialPaused: true,
    volume: 100,
    handlePlayerUpdate: () => console.warn('no Player provider'),
    handleVolumeUpdate: () => console.warn('no Player provider'),
    handleForward: () => console.warn('no Player provider'),
    handleFullscreenUpdate: () => console.warn('no Player provider'),
    handleRewind: () => console.warn('no Player provider'),
    handlePlayerPausedState: () => console.warn('no Player provider'),
});

export function usePlayerControls() {
    return useContext(PlayerControlsContext);
}

const onPlay = (player, callback) => {
    if (player.addEventListener) {
        player.addEventListener('play', callback);
    } else if (player.onPlay) {
        player.onPlay = callback;
    }
};

const onPause = (player, callback) => {
    if (player.addEventListener) {
        player.addEventListener('pause', callback);
    } else if (player.onPause) {
        player.onPause = callback;
    }
};

const onWaiting = (player, callback) => {
    if (player.addEventListener) {
        player.addEventListener('waiting', callback);
    } else if (player.onWaiting) {
        player.onWaiting = callback;
    }
};

const onPlaying = (player, callback) => {
    if (player.addEventListener) {
        player.addEventListener('playing', callback);
    }
};

const onProgress = (player, callback) => {
    if (player.addEventListener) {
        player.addEventListener('timeupdate', () => callback(player.currentTime));
    } else if (player.onWaiting) {
        document.addEventListener('player-timeupdate', function (e) {
            callback(e.detail.currentTime);
        });
    }
};

const onLoadMetadata = (player, callback) => {
    if (player.addEventListener) {
        player.addEventListener('loadedmetadata', () => callback(player.duration));
    } else if (player.onWaiting) {
        document.addEventListener('player-metaloaded', function (e) {
            callback(e.detail.duration);
        });
    }
};

export function PlayerControlsProvider({ children }) {
    const [player, setPlayer] = useState(null);
    const [paused, setPaused] = useState(true);
    const [metaLoaded, setMetaLoaded] = useState(false);
    const [fullscreen, setFullscreen] = useState(false);
    const [initialPaused, setInitialPaused] = useState(true);
    const [loading, setLoading] = useState(true);
    const [volume, setVolume] = useState(100);

    const handleVolumeUpdate = useCallback(
        (_volume) => {
            if (player) {
                if (player.setVolume) {
                    window.player.setVolume(_volume);
                    setVolume(_volume);
                } else {
                    player.volume = _volume / 100;
                    setVolume(_volume);
                }
            }
        },
        [player]
    );

    const handleFullscreenUpdate = useCallback(
        (_fullscreen) => {
            setFullscreen(_fullscreen);
        },
        []
    );

    const handlePlayerPausedState = useCallback(() => {
        if (player) {
            if (player.paused) {
                player.play()
                    .then(() => {
                        setInitialPaused(false);
                        setPaused(false);
                        setLoading(false);
                    })
                    .catch((e) => {
                        console.log('Unable to play', e);
                    });
            } else {
                setPaused(true);
                player.pause();
            }
        }
    }, [player]);

    const handlePlayerUpdate = useCallback(
        (_player) => {
            setPlayer(_player);
            if (_player) {
                const ProgressBarElement = document.getElementById('player-progressbar');
                const TotalTimeElement = document.getElementById('player-total-time');
                const CurrentTimeElement = document.getElementById('player-current-time');

                onPlay(_player, () => {
                    setPaused(false);
                    setLoading(false);
                });

                onPause(_player, () => {
                    setPaused(true);
                });

                onWaiting(_player, () => {
                    setLoading(true);
                });

                onPlaying(_player, () => {
                    setLoading(false);
                });

                onProgress(_player, (currentTime) => {
                    if (ProgressBarElement) {
                        ProgressBarElement.value = currentTime.toString();
                    }
                    if (CurrentTimeElement) {
                        CurrentTimeElement.innerHTML = new Date(currentTime * 1000).toISOString().substr(11, 8);
                    }
                });

                onLoadMetadata(_player, (duration) => {
                    if (ProgressBarElement) {
                        ProgressBarElement.max = duration.toString();
                    }
                    if (TotalTimeElement) {
                        TotalTimeElement.innerHTML = new Date(duration * 1000).toISOString().substr(11, 8);
                    }
                    setLoading(false);
                    setMetaLoaded(true);
                    handleVolumeUpdate(100);
                });

                if (ProgressBarElement) {
                    ProgressBarElement.addEventListener('input', () => {
                        if (_player.seekTo) {
                            window.player.seekTo(parseInt(ProgressBarElement.value));
                        } else if (_player.currentTime) {
                            _player.currentTime = ProgressBarElement.value;
                        }
                    });
                }

                handleVolumeUpdate(100);
                setTimeout(() => {
                    if (_player.paused) {
                        handlePlayerPausedState();
                    }
                }, 100);
            }
        },
        [handlePlayerPausedState, handleVolumeUpdate]
    );

    const handleRewind = useCallback(() => {
        if (player) {
            if (player.seekTo) {
                const ProgressBarElement = document.getElementById('player-progressbar');
                window.player.seekTo(parseInt(ProgressBarElement.value) - 10);
            } else {
                player.currentTime -= 10;
            }
        }
    }, [player]);

    const handleForward = useCallback(() => {
        if (player) {
            if (player.seekTo) {
                const ProgressBarElement = document.getElementById('player-progressbar');
                window.player.seekTo(parseInt(ProgressBarElement.value) + 10);
            } else {
                player.currentTime += 10;
            }
        }
    }, [player]);

    return (
        <PlayerControlsContext.Provider
            value={{
                handlePlayerUpdate,
                paused,
                loading,
                metaLoaded,
                handleVolumeUpdate,
                volume,
                handleFullscreenUpdate,
                fullscreen,
                initialPaused,
                handlePlayerPausedState,
                handleForward,
                handleRewind,
                player,
            }}
        >
            {children}
        </PlayerControlsContext.Provider>
    );
}
