import PropTypes from 'prop-types';
import { format } from 'date-fns';
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material';

interface MonthSelectorProps {
    monthsToShow?: number;
    setSelectedMonthRange: (dateRange: { firstDay: string; lastDay: string, month: string }) => void;
    selectedMonthRange: any
}

const MonthSelector: React.FC<MonthSelectorProps> = ({ monthsToShow = 12, setSelectedMonthRange, selectedMonthRange }) => {
    const getLastNMonths = (n: number) => {
        const months = [];
        const today = new Date();
        for (let i = 0; i < n; i++) {
            const date = new Date(today.getFullYear(), today.getMonth() - i, 1);
            months.push({
                label: date.toLocaleString('default', { month: 'long', year: 'numeric' }),
                value: format(date, 'yyyy-MM'), // YYYY-MM
                date,
            });
        }
        return months;
    };

    const months = getLastNMonths(monthsToShow);

    const handleMonthChange = (event: SelectChangeEvent<string>) => {
        const selectedValue = event.target.value;
        if (selectedValue) {
            const [year, month] = selectedValue.split('-').map(Number);
            setSelectedMonthRange({
                firstDay: format(new Date(year, month - 1, 1), 'yyyy-MM-dd HH:mm:ss'),
                lastDay: format(new Date(year, month, 0), 'yyyy-MM-dd HH:mm:ss'),
                month: format(new Date(year, month - 1, 1), 'yyyy-MM')
            })
        }
    };

    return (
        <div className="w-40 mr-[100px]">
            <FormControl variant="outlined" fullWidth>
                <InputLabel id="monthSelector-label">Select Month</InputLabel>
                <Select
                    labelId="monthSelector-label"
                    id="monthSelector"
                    value={selectedMonthRange?.month}
                    onChange={handleMonthChange}
                    label="Select Month"
                >
                    <MenuItem value="">
                        <em>None</em>
                    </MenuItem>
                    {months.map((month) => (
                        <MenuItem key={month.value} value={month.value}>
                            {month.label}
                        </MenuItem>
                    ))}
                </Select>

            </FormControl>
        </div>
    );
};

MonthSelector.propTypes = {
    monthsToShow: PropTypes.number,
    setSelectedMonthRange: PropTypes.func.isRequired,
};

export default MonthSelector;
