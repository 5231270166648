import { ArrowLeft } from "lucide-react";

export default function Header({ hideControls }) {

  return (
    <div
      id="player-header"
      onClick={() => window.history.back()}
      className="absolute cursor-pointer transition-all duration-200 gap-2 left-2 md:left-4 bg-black bg-opacity-70 z-20 flex items-center text-white rounded-lg"
    >
      {/* <div>
        <ArrowLeft className="player-icons" />
      </div> */}
      {!hideControls && (
        <div className="flex-1">
          <div className="text-white select-none">Back</div>
        </div>
      )}
    </div>
  );
}
