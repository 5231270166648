import {

    List,
    Datagrid,
    TextField, NumberField, ReferenceField, TopToolbar, CreateButton, ExportButton, useRecordContext, BooleanField,
} from 'react-admin';
import FormattedDateTimeField from "../../../common/FormattedDateTimeField";
import { NoDataSection } from "../../../common/NoDataSection";
import { downloadCSV, jsonToCSV } from '../../../common/CSVExport';
import { format } from 'date-fns';
import MonthSelector from '../../../common/MonthSelector';
import { useState } from 'react';

const myCustomExporter = (records: any) => {
    const data = records.map((record: any) => {
        return {
            ...record,
            started_date: format(new Date(record.started_date), 'dd-MMM-yyyy hh:mm a'),
            ended_at: format(new Date(record.ended_at), 'dd-MMM-yyyy hh:mm a'),
        };
    });
    const csv = jsonToCSV(data); // Convert the modified data to CSV format
    downloadCSV(csv, 'Tests Report');  // Download the CSV
};



const ReportTestsList = () => {
   const [selectedMonthRange, setSelectedMonthRange] = useState<{ firstDay: any, lastDay: any, month: any } | null>(null);
       const ListActions = () => (
           <TopToolbar className="">
               <MonthSelector monthsToShow={12} setSelectedMonthRange={setSelectedMonthRange} selectedMonthRange={selectedMonthRange} />
               <ExportButton />
           </TopToolbar>
       );

    return (

        <List
            actions={<ListActions />}
            filter={{
                started_date: {
                    format: 'hasura-raw-query',
                    value: {
                        _gt: selectedMonthRange?.firstDay,
                        _lt: selectedMonthRange?.lastDay
                    }
                },
            }}
            exporter={myCustomExporter}
            resource={'report_tests'}
            empty={<NoDataSection resource={'report_tests'} />}
        >
            <Datagrid>
                <TextField source="name" />
                <TextField source="email" />
                <TextField source="phone" />
                <TextField source="test" />
                <TextField source="subjects" />
                <TextField source="attempted_questions" />
                <TextField source="unattempted_questions" />
                <TextField source="correct_questions" />
                <TextField source="skipped_questions" />
                <TextField source="total_questions" />
                <TextField source="user_score" />
                <TextField source="total_marks" />
                <FormattedDateTimeField source="started_date" />
                <FormattedDateTimeField source="ended_at" />
            </Datagrid>
        </List>
    );
};
export default ReportTestsList;
