import React, { createContext, useContext, useEffect, useState } from 'react';

const PlayerScheduleContext = createContext({
    videoData: undefined,
    url: "",
    type: "",
    sidebarMode: '',
    setSidebarMode: () => console.warn('no Player provider'),
    setVideoData: (video) => console.warn('no Player provider'),
});

export function usePlayerSchedule() {
    return useContext(PlayerScheduleContext);
}

export function PlayerScheduleProvider({ children, url, type, title }) {
    const [sidebarMode, setSidebarMode] = useState('');
    const [videoData, setVideoData] = useState(undefined);
    const [qualities, setQualities] = useState([]);
    const [selectedQuality, setSelectedQuality] = useState('Auto');

    useEffect(() => {

        if (type === 'SECURE_V2') {
            setVideoData({ videoType: type, url: url+"master.m3u8", title })
        }
        if (type === 'YOUTUBE') {
            setVideoData({ videoType: type, url: url, title })
        }



        setQualities(['Auto', ...(videoData?.qualities || [])]);
        setSelectedQuality('Auto');
    }, []);

    return (
        <PlayerScheduleContext.Provider
            value={{
                videoData,
                setVideoData,
                sidebarMode,
                url, 
                type,
                setSidebarMode,
                qualities,
                setQualities: setQualities,
                quality: selectedQuality,
                setQuality: setSelectedQuality
            }}
        >
            {children}
        </PlayerScheduleContext.Provider>
    );
}
