import * as React from 'react';
import {Create, TextInput, required, SimpleForm, DateInput, SelectInput, ReferenceInput} from 'react-admin';
import ImageInput from '../../common/inputs/ImageInput';
import {useParams} from "react-router";
import {LoadingSection} from "../../common/LoadingSection";
import HTMLEditorInput, {validHtml} from "../../common/inputs/HTMLEditorInput";
import {STATUS} from "../../common/constants";


const ChapterCreate = () => {
    let {programId, subjectId} = useParams();
    if (!subjectId) {
        return <LoadingSection/>
    }
    return <Create redirect={() => {
        return `programs/${programId}/subjects/${subjectId}/chapters`
    }} resource={'chapters'}>
        <SimpleForm defaultValues={{program_id: programId, subject_id: subjectId}}>
            <ImageInput source="image" fullWidth/>
            <TextInput source="name" fullWidth validate={[required()]}/>
            <TextInput source="slug" fullWidth validate={[required()]}/>
            <TextInput source="display_order" type={'number'} fullWidth/>

            <ReferenceInput label="Program" source="program_id" reference="programs">
                <SelectInput optionText="name" disabled fullWidth validate={[required()]}/>
            </ReferenceInput>
            <ReferenceInput label="Subject" source="subject_id" reference="subjects">
                <SelectInput optionText="name" disabled fullWidth validate={[required()]}/>
            </ReferenceInput>
            <HTMLEditorInput source={'description'} fullWidth validate={[required(), validHtml]}/>
        </SimpleForm>
    </Create>
};

export default ChapterCreate;
