import VideoPlayer from ".";
import { usePlayerSchedule } from "../../context/PlayerScheduleContext";
import { cn } from "./util";


export default function PlayerContentWrapper({hideControls}) {
  const {sidebarMode, setSidebarMode} = usePlayerSchedule();

  const rootClassName = cn(' overflow-hidden md:aspect-auto w-full md:h-full flex flex-col items-center justify-start md:justify-center', {
    'h-full': !sidebarMode,
    'aspect-square': sidebarMode,
  })
  return (
    <div className={rootClassName}>
      <VideoPlayer hideControls={hideControls}/>
    </div>
  )
}
