import * as React from 'react';
import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, required, ReferenceInput, SelectInput, minValue,
} from 'react-admin';
import { Topics } from "../../custom";
import { EditToolbar } from "../../common/EditToolbar";
import { useParams } from "react-router";
import { LoadingSection } from "../../common/LoadingSection";
import { STATUS } from "../../common/constants";


const TopicEdit = () => {
    let { programId, subjectId, chapterId, topicId } = useParams();
    if (!chapterId) {
        return <LoadingSection />
    }
    return <Edit mutationMode={'pessimistic'} title={<TopicTitle />} redirect={() => {
        return `programs/${programId}/subjects/${subjectId}/chapters/${chapterId}/topics`
    }} resource={'topics'} id={topicId}>
        <SimpleForm toolbar={<EditToolbar />}>
            <TextInput source="name" fullWidth validate={[required()]} />
            <TextInput source="slug" fullWidth validate={[required()]} />
            <SelectInput
                source="status"
                label={'Status'}
                fullWidth
                validate={[required()]}
                choices={STATUS}
            />
            <ReferenceInput label="Program" source="program_id" reference="programs">
                <SelectInput optionText="name" disabled fullWidth validate={[required()]} />
            </ReferenceInput>
            <ReferenceInput label="Subject" source="subject_id" reference="subjects">
                <SelectInput optionText="name" disabled fullWidth validate={[required()]} />
            </ReferenceInput>
            <ReferenceInput label="Chapter" source="chapter_id" reference="chapters">
                <SelectInput optionText="name" disabled fullWidth validate={[required()]} />
            </ReferenceInput>
            <TextInput source="display_order" type={'number'} fullWidth validate={[minValue (0)]} />
        </SimpleForm>
    </Edit>
};

const TopicTitle = () => {
    const record = useRecordContext<Topics>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.topics.name', { smart_count: 1 })} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

export default TopicEdit;
