import PlayPauseAction from "./actions/PlayPauseAction";
import {useEffect} from "react";
import {Check, Redo, Settings, Undo, Volume2, VolumeX} from "lucide-react";
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuItem,
    DropdownMenuTrigger
} from "./ui/dropdown-menu";
import ProgressBar from "./actions/ProgressBar";
import FullscreenAction from "./actions/FullscreenAction";
import { usePlayerControls } from "../../context/PlayerControlsContext";
import { usePlayerSchedule } from "../../context/PlayerScheduleContext";
import { cn } from "./util";


const VolumeControl = () => {
    const {player, handleVolumeUpdate, volume} = usePlayerControls();
    useEffect(() => {
    }, [handleVolumeUpdate, player]);

    return (
        <div
            className={
                "flex max-w-[120px] items-center group custom-volume-slider"
            }
        >
            {volume ? (
                <Volume2
                    className={"player-icons"}
                    onClick={() => {
                        handleVolumeUpdate(0);
                    }}
                />
            ) : (
                <VolumeX
                    className={"player-icons"}
                    onClick={() => {
                        handleVolumeUpdate(90);
                    }}
                />
            )}
            <input
                type="range"
                value={volume}
                onChange={(e) => {
                    handleVolumeUpdate(e.target.value);
                }}
                className="transition-all ml-2 duration-500 w-full opacity-70 h-1 group-hover:opacity-100 bg-gray-50 text-white rounded-lg appearance-none cursor-pointer range-sm"
            />
        </div>
    );
};

export function QualityControl() {
    const {qualities, setQuality, quality} = usePlayerSchedule();
    const {paused, handlePlayerPausedState} = usePlayerControls();

    return <DropdownMenu className={'z-[10000000]'}>
        <DropdownMenuTrigger
            className={'flex items-center gap-1 px-1.5 py-0.5 rounded bg-white text-black'}>
            <Settings className={'w-4 h-4 stroke-black'}/>
            <div className={'text-xs '}>
                {quality}
            </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className={'bg-black z-[10000000]'}>
            {
                qualities?.map((_, index) => {
                    return <DropdownMenuItem className={cn('cursor-pointer flex justify-between', {
                        'bg-primary text-white': quality === _,
                    })} onClick={() => {
                        setQuality?.(_);
                        // @ts-ignore
                        window.hls.currentLevel = _ === 'Auto' ? -1 : (index - 1);
                        // @ts-ignore
                        hls.startLoad(Math.max(window.hls.media.currentTime - 10, 0));
                        if (!paused) {
                            handlePlayerPausedState()
                            setTimeout(() => {
                                handlePlayerPausedState()
                            }, 100);
                        }
                    }} key={index}><span className="text-white">{_}</span>{quality === _ && <Check className={'h-4 w-4'}/>}
                    </DropdownMenuItem>
                })
            }
        </DropdownMenuContent>
    </DropdownMenu>
}

export default function Footer({hideControls}) {
    const {handleRewind,  handleForward} = usePlayerControls();
    const {videoData} = usePlayerSchedule();
    return <div
        id={'player-footer'}
        className={'absolute select-none transition-all duration-200 gap-1 left-2 md:left-4 right-2 md:right-4 rounded-lg z-20 py-1 px-3 flex flex-col'}>
        <div className={'items-center gap-3 flex'}>
            <div className={'text-white hidden md:flex text-sm md:text-base font-medium capitalize line-clamp-1'}>
                {videoData?.title}
            </div>
            <div className={'w-1 h-1 rounded-full bg-white bg-opacity-20'}/>
            <div className={'flex-1'}/>
            <div
                className={'flex items-center text-center text-xs bg-black bg-opacity-60 border-white border rounded-md px-1 py-0.5'}>
                <div id={'player-current-time'} className={'w-[65px] text-center text-white text-xs'}>
                    --:--:--
                </div>
                <div className={'text-xs text-white'}>
                    /
                </div>
                <div id={'player-total-time'} className={'w-[65px] text-center text-white text-xs'}>
                    --:--:--
                </div>
            </div>
        </div>
        <div className={'flex items-center gap-2'}>
            <div className={'hidden md:flex items-center gap-2'}>
                <Undo onClick={handleRewind} className={'player-icons stroke-white'}/>
                <PlayPauseAction/>
                <Redo onClick={handleForward} className={'player-icons stroke-white'}/>
            </div>
            <div className={'flex-1'}>
                <ProgressBar/>
            </div>
            <div className={' items-center gap-3 hidden md:flex'}>
                <VolumeControl/>
                <QualityControl/>
            </div>
            <FullscreenAction/>
        </div>
    </div>
}
