import { useEffect, useState } from "react";
import { Loading, useDataProvider, useNotify } from "react-admin";
import { useParams } from "react-router";
import { useQuery, useMutation, useQueryClient } from "react-query";
import UserDetail from "./UserDetail";
import { Button } from "@mui/material";


const ReplyList = ({ parentId, parentType, replyList, setReplyList,addReplyCalled }: any) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();

    // Fetch comments
    const { data: ReplyData, isLoading, error, refetch } = useQuery(
        ["comments", parentId, parentType],
        () =>
            dataProvider.getList("comments", {
                filter: {
                    parent_id: parentId,
                    parent_type: parentType,
                },
                pagination: { page: 1, perPage: 10 },
                sort: { field: "created_at", order: "DESC" },
            }),
        {
            enabled: !!parentId && !!parentType,
        }
    );

    useEffect(() => {
        refetch()
        setReplyList(ReplyData?.data || []);
    }, [ReplyData,addReplyCalled]);

    const deleteMutation = useMutation(
        (id: string) =>
            dataProvider.delete("comments", {
                id,
            }),
        {
            onSuccess: () => {
                notify("Reply deleted successfully", { type: "info" });
                refetch();
            },
            onError: (error: any) => {
                notify(`Error: ${error.message}`, { type: "warning" });
            },
        }
    );

    const handleDeleteReply = (ReplyId: string) => {
        deleteMutation.mutate(ReplyId);
        refetch();

    };

    return (<>
        {isLoading ?
            <Loading />
            :
            <div className="flex flex-col gap-3 ">
                {replyList?.map((reply: any) => (
                    <div
                        key={reply?.id}
                        className="bg-white p-4 border ml-16"
                    >
                        <div className="flex justify-between items-center cursor-pointer">
                            <div className="flex gap-3  w-4/6">
                                <div className="flex gap-3 flex-col w-full ">
                                    <h3 className=" text-gray-800 w-2/3">
                                        <UserDetail userId={reply?.user_id} createdAt={reply?.created_at} />
                                    </h3>

                                    <div className="flex flex-col pl-16">
                                        <p className="text-gray-900">{reply?.content}</p>
                                    </div>
                                </div>
                            </div>

                            <div className="flex gap-4 items-center">
                                <Button
                                    color="error"
                                    className="text-red-500 text-sm  hover:font-bold"
                                    onClick={() => handleDeleteReply(reply.id)}
                                >
                                    Delete
                                </Button>
                            </div>
                        </div>

                    </div>
                ))}
            </div>
        }
    </>

    )
}

export default ReplyList
