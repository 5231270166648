import {
  List,
  Datagrid,
  TextField,
  NumberField,
  ReferenceField,
  ImageField,
  TopToolbar,
} from "react-admin";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { BulkUploadQuestionBankAction } from "./BulkUploadQuestions";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";

// const ListFilter = (props: any) => (
//   <Filter {...props}>
//     <TextInput label="Search" source="name" alwaysOn />
//     <SelectInput source="status" label={"Status"} choices={STATUS} alwaysOn />
//   </Filter>
// );

const CustomListActions = () => (
  <TopToolbar>
    <BulkUploadQuestionBankAction />
    {/* <ExportButton /> */}
  </TopToolbar>
);

const QuestionBankList = () => {
  return (
    <List
      resource={"questions"}
      // filters={<ListFilter />}
      actions={<CustomListActions />}
      sort={{ field: "created_at", order: "DESC" }}
    >
      <Datagrid>
        <TextField source="name" />
        <ImageField source="image" />
        <TextField source="content" />
        <FormattedDateTimeField source="created_at" />
        {/* <EditButton /> */}
        <DeleteWithDialogAction />
      </Datagrid>
    </List>
  );
};
export default QuestionBankList;
