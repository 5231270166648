import { useEffect, useState } from "react";
import {
    useDataProvider,
    useNotify,
} from "react-admin";
import { useParams } from "react-router";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { LoadingSection } from "../../common/LoadingSection";
import { Button } from "@mui/material";
import ReplyList from "../../common/comments/ReplyList";
import UserDetail from "../../common/comments/UserDetail";

const CommentsList = () => {
    const params = useParams();
    const dataProvider = useDataProvider();
    const [newComment, setNewComment] = useState<string>("");
    const [replyContent, setReplyContent] = useState<{ [key: string]: string }>({});
    const notify = useNotify();
    const queryClient = useQueryClient();
    const [commentsList, setCommentsList] = useState([]) as any
    const [replyList, setReplyList] = useState([]) as any
    const [addReplyCalled, setAddReplyCalled] = useState(false)
    const [activeComment, setActiveComment] = useState<number | null>(null);
    const [replyingTo, setReplyingTo] = useState<number | null>(null);

    const toggleReplies = (id: number) => {
        setActiveComment((prev) => (prev === id ? null : id));
    };

    // Fetch comments
    const { data: commentsData, isLoading, error, refetch } = useQuery(
        ["comments", params?.parentId, params?.parentType],
        () =>
            dataProvider.getList("comments", {
                filter: {
                    parent_id: params?.parentId,
                    parent_type: params?.parentType,
                },
                pagination: { page: 1, perPage: 10 },
                sort: { field: "created_at", order: "DESC" },
            }),
        {
            enabled: !!params?.parentId && !!params?.parentType,
        }
    );

    useEffect(() => {
        setCommentsList(commentsData?.data || []);
    }, [commentsData]);

    // Add comment mutation
    const addMutation = useMutation(
        (newCommentData: { parent_id: string; parent_type: string; content: string }) =>
            dataProvider.create("comments", {
                data: newCommentData,
            }),
        {
            onSuccess: () => {
                notify("Comment added successfully", { type: "info" });
                setNewComment("");
                queryClient.invalidateQueries(["comments", params?.parentId, params?.parentType]); // Invalidate cache to trigger refetch
                refetch(); // Explicitly refetch comments
            },
            onError: (error: any) => {
                notify(`Error: ${error.message}`, { type: "warning" });
            },
        }
    );

    // Delete comment mutation
    const deleteMutation = useMutation(
        (id: string) =>
            dataProvider.delete("comments", {
                id,
            }),
        {
            onSuccess: () => {
                notify("Comment deleted successfully", { type: "info" });
                refetch(); // Explicitly refetch comments
            },
            onError: (error: any) => {
                notify(`Error: ${error.message}`, { type: "warning" });
            },
        }
    );

    // add reply 
    const replyMutation = useMutation(
        (replyData: { parent_id: number; parent_type: string; content: string }) =>
            dataProvider.create("comments", {
                data: replyData,
            }),
        {
            onSuccess: () => {
                notify("Reply added successfully", { type: "info" });
                setReplyContent((prev) => ({ ...prev, [replyingTo!]: "" }))
                setAddReplyCalled((prev: any) => !prev)
            },
            onError: (error: any) => {
                notify(`Error: ${error.message}`, { type: "warning" });
            },
        }
    );

    const handleAddComment = () => {
        if (newComment.trim() === "") {
            notify("Comment cannot be empty", { type: "warning" });
            return;
        }
        addMutation.mutate({
            parent_id: params?.parentId!,
            parent_type: params?.parentType!,
            content: newComment,
        });
    };

    const handleDeleteComment = (commentId: string) => {
        deleteMutation.mutate(commentId);
    };

    const handleAddReply = (parentId: number) => {
        if (!replyContent[parentId] || replyContent[parentId].trim() === "") {
            notify("Reply cannot be empty", { type: "warning" });
            return;
        }
        replyMutation.mutate({
            parent_id: parentId,
            parent_type: "COMMENT",
            content: replyContent[parentId],
        });
    };

    if (error) {
        return <>Error Fetching comments</>;
    }

    return (<>
        <div className="bg-gray-100 p-10">
            <h2 className="text-4xl font-semibold text-center pb-5">Comments</h2>
            <>
                {isLoading ?
                    <LoadingSection />
                    :
                    !error ?
                        <>
                            <div className=" flex gap-5 my-3 mx-auto">
                                <input
                                    type="text"
                                    className="border border-gray-300 rounded-lg  w-4/5 p-2"
                                    placeholder="Add a new comment..."
                                    value={newComment}
                                    onChange={(e) => setNewComment(e.target.value)}
                                />

                                <Button
                                    className="bg-blue-500 text-white px-4 py-2 text-md rounded-lg hover:bg-blue-600"
                                    onClick={handleAddComment}
                                    disabled={addMutation.isLoading}
                                >
                                    {addMutation.isLoading ? "Adding..." : "Add Comment"}
                                </Button>
                            </div>

                            <div className="space-y-4">
                                {commentsList?.map((comment: any) => (
                                    <div
                                        key={comment?.id}
                                        className="bg-white p-4 shadow rounded-md border border-gray-200"
                                    >
                                        <div className="flex justify-between items-center cursor-pointer">
                                            <div className="flex gap-3  w-4/6">
                                                <div className="flex gap-3 flex-col w-full ">
                                                    <h3 className=" text-gray-800 w-2/3">
                                                        <UserDetail userId={comment?.user_id} createdAt={comment?.created_at} />
                                                    </h3>

                                                    <div className="flex flex-col pl-16">
                                                        <p className="text-gray-900">{comment?.content}</p>
                                                        {replyingTo === comment.id ? (
                                                            <div className="mt-4">
                                                                <input
                                                                    type="text"
                                                                    className="border border-gray-300 rounded-lg w-full p-2"
                                                                    placeholder="Write a reply..."
                                                                    value={replyContent[comment.id] || ""}
                                                                    onChange={(e) =>
                                                                        setReplyContent((prev) => ({
                                                                            ...prev,
                                                                            [comment.id]: e.target.value,
                                                                        }))
                                                                    }
                                                                />

                                                                <div className="flex gap-10 justify-end mt-3">

                                                                    <Button
                                                                        onClick={() => setReplyingTo(null)}
                                                                        className="text-blue-500 text-sm hover:underline w-[50px]"
                                                                    >
                                                                        Cancel
                                                                    </Button>

                                                                    <Button
                                                                        className="bg-green-500 text-white px-4 py-2 mt-2 rounded-lg hover:bg-green-600"
                                                                        onClick={() => handleAddReply(comment.id)}
                                                                        disabled={replyMutation.isLoading}
                                                                        variant="contained"
                                                                    >
                                                                        {replyMutation.isLoading ? "Replying..." : "Reply"}
                                                                    </Button>
                                                                </div>

                                                            </div>
                                                        )
                                                            :
                                                            <Button
                                                                onClick={() => setReplyingTo((prev) => (prev === comment.id ? null : comment.id))}
                                                                className="text-blue-500 text-sm hover:underline w-[50px]"
                                                            >
                                                                {replyingTo === comment.id ? "Cancel" : "Reply"}
                                                            </Button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="flex gap-4 items-center">
                                                <Button onClick={() => toggleReplies(comment?.id)} className="text-gray-500  text-sm hover:font-bold">
                                                    {activeComment === comment?.id ? "Hide Replies" : "See Replies"}
                                                </Button>

                                                <Button
                                                    color="error"
                                                    className="text-red-500 text-sm  hover:font-bold"
                                                    onClick={() => handleDeleteComment(comment.id)}
                                                >
                                                    Delete
                                                </Button>
                                            </div>
                                        </div>

                                        {activeComment === comment?.id && (
                                            <>
                                                <ReplyList
                                                    parentId={comment?.id}
                                                    parentType={"COMMENT"}
                                                    replyList={replyList}
                                                    setReplyList={setReplyList}
                                                    addReplyCalled={addReplyCalled}
                                                />
                                            </>
                                        )}

                                    </div>
                                ))}
                            </div>
                        </> : <div className="min-h-screen">
                            Error Fetching Comments"
                        </div>
                }
            </>
        </div>
    </>
    );
};

export default CommentsList;
