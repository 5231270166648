import React, { useEffect, useState } from 'react';
import {
    List,
    Datagrid,
    TextField,
    TopToolbar,
    ExportButton, DateInput, Filter, useListContext, ListActions, useListFilterContext,
} from 'react-admin';
import FormattedDateTimeField from "../../../common/FormattedDateTimeField";
import { NoDataSection } from "../../../common/NoDataSection";
import { format, } from 'date-fns';
import { downloadCSV, jsonToCSV } from '../../../common/CSVExport';
import MonthSelector from '../../../common/MonthSelector';

const myCustomExporter = (records: any) => {
    const data = records.map((record: any) => ({
        ...record,
        joined_at: format(new Date(record.joined_at), 'dd-MMM-yyyy hh:mm a'),
        last_activity_at: format(new Date(record.last_activity_at), 'dd-MMM-yyyy hh:mm a'),
    }));
    const csv = jsonToCSV(data); // Convert the modified data to CSV format
    downloadCSV(csv, 'Application Users Report');  // Download the CSV
};


const ReportApplicationUsersList = () => {
    const [selectedMonthRange, setSelectedMonthRange] = useState<{ firstDay: any, lastDay: any, month: any } | null>(null);
    const ListActions = () => (
        <TopToolbar className="">
            <MonthSelector monthsToShow={12} setSelectedMonthRange={setSelectedMonthRange} selectedMonthRange={selectedMonthRange} />
            <ExportButton />
        </TopToolbar>
    );
    return (

        <List
            actions={<ListActions />}
            filter={{
                joined_at: {
                    format: 'hasura-raw-query',
                    value: {
                        _gt: selectedMonthRange?.firstDay,
                        _lt: selectedMonthRange?.lastDay
                    }
                },
            }}
            exporter={myCustomExporter}
            resource="report_application_users"
            empty={<NoDataSection resource="report_application_users" />}
        >
            <Datagrid>
                <TextField source="name" />
                <TextField source="admission_number" />
                <TextField source="phone" />
                <TextField source="email" />
                <FormattedDateTimeField source="joined_at" />
                <FormattedDateTimeField source="last_activity_at" />
            </Datagrid>
        </List>
    )
}

export default ReportApplicationUsersList;
