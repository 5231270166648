import { useEffect, useState } from "react";
import { ImageField, TextField, useDataProvider, useNotify } from "react-admin";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { Button, Checkbox } from "@mui/material";
import { LoadingSection } from "../../common/LoadingSection";
import { Pagination } from "@mui/material";

const QuestionsListInput = (props: any = {}) => {
    const [questions, setQuestions] = useState<any[]>([]);
    const [selectedQuestions, setSelectedQuestions] = useState<any[]>([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [perPage] = useState(10); // Number of questions per page
    const notify = useNotify();
    const dataProvider = useDataProvider();

    useEffect(() => {
        setLoading(true);
        dataProvider
            .getList("questions", {
                pagination: { page, perPage },
                sort: { field: "created_at", order: "DESC" },
                filter: {},
            })
            .then((response) => {
                setQuestions(response.data);
                setTotal(response?.total || 10);
                setLoading(false);
            })
            .catch((error) => {
                notify("Failed to load questions", { type: "error" });
                setLoading(false);
            });
    }, [dataProvider, notify, page, perPage]);

    const handleSelect = (id: any) => {
        setSelectedQuestions((prev) =>
            prev.includes(id) ? prev.filter((q) => q !== id) : [...prev, id]
        );
    };

    const handleSave = () => {
        console.log("selected", selectedQuestions);
    };

    const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    return (
        <div className="min-w-[70vw] min-h-[70vh]">
            {loading ? (
                <LoadingSection />
            ) : (
                <div className="min-w-[70vw] max-w-full">
                    <div className="relative border rounded-lg overflow-hidden">
                        <div style={{ maxHeight: "70vh", overflowY: "auto" }}>
                            <table className="min-w-full table-auto border-collapse">
                                <thead>
                                    <tr className="bg-gray-100 border-b border-gray-200 sticky top-0 z-10">
                                        <th className="p-2"></th>
                                        <th className="p-2">Name</th>
                                        <th className="p-2">Image</th>
                                        <th className="p-2">Created</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {questions.map((question: any) => (
                                        <tr
                                            key={question.id}
                                            className="border-b border-gray-200 hover:bg-gray-50"
                                        >
                                            <td className="p-1">
                                                <Checkbox
                                                    checked={selectedQuestions.includes(question.id)}
                                                    onChange={() => handleSelect(question.id)}
                                                />
                                            </td>
                                            <td className="p-1 text-center">
                                                <TextField source="name" record={question} label="Name" />
                                            </td>
                                            <td className="p-1 flex justify-center">
                                                <ImageField source="image" record={question} />
                                            </td>
                                            <td className="p-1 text-center">
                                                <FormattedDateTimeField source="created_at" record={question} />
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="sticky bottom-0 bg-white z-10">
                            <Pagination
                                count={Math.ceil(total / perPage)}
                                page={page}
                                onChange={handlePageChange}
                                color="primary"
                                className="py-2"
                                style={{ width: "100%", display: "flex", justifyContent: "center" }}
                            />
                        </div>
                    </div>

                    <div className=" p-2 flex flex-row gap-3">
                        <Button variant="contained" color="primary" onClick={handleSave}>
                            Save
                            {selectedQuestions.length > 0 && (
                                <div className="font-semibold px-1">
                                    (
                                    {selectedQuestions.length}
                                    )
                                </div>
                            )}
                        </Button>
                        <Button onClick={props?.handleClose}>Cancel</Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default QuestionsListInput;
