import {useEffect} from "react";
import { usePlayerSchedule } from "../../../context/PlayerScheduleContext";
import { isLectureLive } from "../util";

export default function ProgressBar() {
    const {schedule} = usePlayerSchedule()


    return <div className={'gap-2 flex items-center'}>
        <div
            className={'flex-1 flex relative h-[32px]'}>
            {isLectureLive(schedule?.start_time, schedule?.end_time) ? <div id={'player-progressbar-completed'}
                                                                            className={'absolute top-[14px] rounded-full h-1 right-0 left-0 bg-indigo-500'}/> :
                <input type={"range"} onProgress={(e) => {
                    console.log(e)
                }} id={'player-progressbar'} className={'w-full'}/>}

        </div>
    </div>
}
