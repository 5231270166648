import { useCallback, useEffect, useState } from "react";
import PlayerContentWrapper from "./PlayerContentWrapper";
import PlayerSidebarWrapper from "./PlayerSidebarWrapper";
import { PlayerControlsProvider, usePlayerControls } from "../../context/PlayerControlsContext";
import { PlayerScheduleProvider } from "../../context/PlayerScheduleContext";

export default function VideoPlayerPage({ url, type, title }) {
    let timer = null;
    const [hideControls, setHideControls] = useState(true);
    const { player } = usePlayerControls();
    const handleMouseMove = useCallback(() => {
        if (timer) {
            clearTimeout(timer);
        }
        const footerDoc = document.getElementById('player-footer')
        const headerDoc = document.getElementById('player-header')
        if (footerDoc && headerDoc) {
            footerDoc.style.bottom = '10px';
            headerDoc.style.top = '10px';
            if (!player?.paused) {
                timer = setTimeout(() => {
                    footerDoc.style.bottom = '-200px';
                    headerDoc.style.top = '-200px';
                    footerDoc.click();
                }, 5000);
            }
        }
    }, [player])


    useEffect(() => {
        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [handleMouseMove]);

    useEffect(() => {
        handleMouseMove();
    }, [handleMouseMove,])


    return <div className={'flex flex-col md:flex-row h-full overflow-hidden'} id={'video-player'}>
        <PlayerScheduleProvider url={url} type={type} title={title}>
            <PlayerControlsProvider>
                <PlayerContentWrapper hideControls={hideControls} />
                {
                    hideControls && <PlayerSidebarWrapper />
                }
            </PlayerControlsProvider>
        </PlayerScheduleProvider>
    </div>
}
