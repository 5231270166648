import {
    Edit,
    SimpleForm,
    TextInput,
    useTranslate,
    useRecordContext, SelectInput, ReferenceInput, 
} from 'react-admin';

import {Ecom_Attribute_Values,} from "../../custom";
import {EditToolbar} from "../../common/EditToolbar";
import {useParams} from "react-router";
import {LoadingSection} from "../../common/LoadingSection";

const AttributeValueEdit = () => {
    let {categoryId, attributeId, attributeValueId} = useParams();
    if (!attributeValueId) {
        return <LoadingSection/>
    }
    return <Edit title={<AttributeValueTitle/>}  redirect={() => {
        return `ecom_categories/${categoryId}/ecom_attributes/${attributeId}/ecom_attribute_values`
    }} resource={'ecom_attribute_values'} id={attributeValueId}>
        <SimpleForm toolbar={<EditToolbar/>}>
            <TextInput source="name" fullWidth/>
            <TextInput source="slug" fullWidth/>
            <ReferenceInput reference={'ecom_attributes'} source={'attribute_id'}>
                <SelectInput disabled fullWidth optionText="name"/>
            </ReferenceInput>
        </SimpleForm>
    </Edit>
};

const AttributeValueTitle = () => {
    const record = useRecordContext<Ecom_Attribute_Values>();
    const translate = useTranslate();

    return record ? (
        <span>
            {translate('resources.attribute_values.name', {smart_count: 1})} &quot;
            {record.name}&quot;
        </span>
    ) : null;
};

export default AttributeValueEdit;
