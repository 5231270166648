import * as React from 'react';
import {
    Create,
    TextInput,
    required,
    SimpleForm,
    SelectInput,
    NullableBooleanInput,
    useCreate, FormDataConsumer,
} from 'react-admin';
import {useParams} from "react-router";
import {LoadingSection} from "../../common/LoadingSection";
import {useNavigate, useSearchParams} from "react-router-dom";
import ImageInput from "../../common/inputs/ImageInput";
import {CONTENT_TYPES, STATUS} from "../../common/constants";
import {useState} from "react";
import VideoAutocompleteInput from "../../common/inputs/VideoAutocompleteInput";
import PDFInput from "../../common/inputs/PDFInput";


const ContentCreate = () => {
    const [create, {isLoading}] = useCreate();
    let {programId, subjectId, chapterId, topicId} = useParams();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();

    const submitContentCreate = (data: any) => {
        if (isLoading) {
            return;
        }
        const contentsData: any = {
            ...data
        }

        const testData = {
            name: contentsData?.title,
            program_id: contentsData?.program_id,
            type: 'EXERCISE'
        }
        const sectionData = { name: "Section 1", program_id: contentsData?.program_id }


        create('tests', {data: testData}, {
            onSuccess: (e) => {
                create('contents', {data: {...contentsData, exercise_id: e.id}}, {
                    onSuccess: (ee) => {
                        create('sections', {data: {...sectionData, test_id: e.id}}, {
                            onSuccess: (eee) => {
                                navigate(-1);
                            }
                        });
                    }
                });
            }
        });
    };


    if (!subjectId || !searchParams.get('type')) {
        return <LoadingSection/>
    }
    return <Create
        // @ts-ignore
        redirect={() => {
            navigate(-1);
        }}
        resource={'contents'}
    >
        <SimpleForm defaultValues={{
            program_id: programId,
            subject_id: subjectId,
            topic_id: topicId,
            type: searchParams.get('type'),
            chapter_id: chapterId,
        }}
                    onSubmit={submitContentCreate}
        >
            <TextInput source="title" fullWidth validate={[required()]}/>
            <ImageInput label={'Thumbnail'} source="thumbnail" fullWidth/>
            <SelectInput
                source="status"
                label={'Status'}
                fullWidth
                validate={[required()]}
                choices={STATUS}
            />
            <SelectInput
                source="type"
                validate={[required()]}
                label={'Type'}
                fullWidth
                disabled={true}
                choices={CONTENT_TYPES}
            />
            <FormDataConsumer>
                {
                    ({formData, ...rest}) => {
                        if (formData.type === 'LECTURES') {
                            return <>
                                <VideoAutocompleteInput type={'VIDEOS'} fullWidth
                                                        formData={formData}
                                                        source={'video_id'}/>
                                <TextInput source="restriction_max_duration_multiple" type={'number'} fullWidth/>
                                <TextInput source="restriction_max_number_playback" type={'number'} fullWidth/>
                            </>
                        }
                        if (formData.type === 'NOTES') {
                            return <PDFInput fullWidth
                                             formData={formData}
                                             source={'pdf_url'}/>
                        }
                        return <></>
                    }
                }
            </FormDataConsumer>
            <NullableBooleanInput source={'is_demo'} fullWidth label={'Is Demo'}/>
            <TextInput source="display_order" type={'number'} fullWidth/>
        </SimpleForm>
    </Create>
};

export default ContentCreate;
