import Button from "@mui/material/Button";
import { CloudUploadOutlined } from "@mui/icons-material";
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Tooltip } from "@mui/material";
import QuestionsListInput from "./QuestionsListInput";

function BulkQuestionUpload({ open, setOpen }: { open: boolean, setOpen: any }) {

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={false}
            sx={{ "& .MuiDialog-paper": { width: "80vw", maxWidth: "80vw" } }}
        >
            <DialogTitle>
                Upload Questions
            </DialogTitle>
            <DialogContent className="">
                <QuestionsListInput handleClose={handleClose} onSuccess={(key: string) => {
                    handleClose();
                }} />
            </DialogContent>
        </Dialog>
    );
}


export const BulkUploadSectionQuestions = ({ path }: any) => {
    const [open, setOpen] = React.useState(false);
    return <>
        <BulkQuestionUpload open={open} setOpen={setOpen} />
        <Tooltip title="Upload Questions">
            <Button sx={{ paddingBottom: "0" }} className="gap-3 pb-0" onClick={() => {
                setOpen(true)
            }}>
                <CloudUploadOutlined />
                Upload Questions
            </Button>
        </Tooltip>
    </>
};

