import {useCallback, useEffect} from "react";
import {Expand, Shrink} from "lucide-react";
import { usePlayerControls } from "../../../context/PlayerControlsContext";


export default function FullscreenAction() {
  const {fullscreen, handleFullscreenUpdate} = usePlayerControls();
  const handleFullscreenChange = useCallback(
    () => {
      handleFullscreenUpdate(!!document.fullscreenElement);
    },
    [handleFullscreenUpdate]
  );
  const enterFullscreen = () => {
    const element = document.documentElement ;
    if (typeof window !== "undefined" && element.requestFullscreen) {
      element.requestFullscreen();
    }
    handleFullscreenUpdate(true);
  };

  const exitFullscreen = () => {
    try {
      if (typeof window !== "undefined" && document.exitFullscreen && document.fullscreenElement) {
        document.exitFullscreen();
      }
      handleFullscreenUpdate(false);
    } catch (e) {
      console.log('error-');
    }
  };
  const handleDoubleClick = useCallback(
    (event) => {
      if (fullscreen) {
        exitFullscreen();
      }
      if (!fullscreen) {
        enterFullscreen();
      }
    },
    [enterFullscreen, exitFullscreen, fullscreen]
  );
  useEffect(() => {
    window.addEventListener("fullscreenchange", handleFullscreenChange);
    return () => {
      window.removeEventListener("fullscreenchange", handleFullscreenChange);
    };
  }, [fullscreen, handleFullscreenChange]);

  useEffect(() => {
    const element = document.getElementById("player-mid");
    if (element) {
      element.addEventListener("dblclick", handleDoubleClick);
    }
    return () => {
      if (element) {
        element.removeEventListener("dblclick", handleDoubleClick);
      }
    };
  }, [fullscreen, handleDoubleClick]);

  return <div>
    {
      fullscreen ? <Shrink onClick={() => exitFullscreen()} className={'player-icons text-white'}/> :
        <Expand onClick={() => enterFullscreen()} className={'text-white'}/>
    }
  </div>
}
