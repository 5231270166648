import React, { useEffect } from "react";
import Hls from "hls.js";
import HLsLoader from "../../lib/HlsLoader";
import { usePlayerControls } from "../../context/PlayerControlsContext";
import { usePlayerSchedule } from "../../context/PlayerScheduleContext";


export default function PlayerCore() {
    const { videoData, qualities, quality, setQualities } = usePlayerSchedule()
    const { handlePlayerUpdate, metaLoaded } = usePlayerControls()

    useEffect(() => {
        if (!videoData) return;
        const video = document.getElementById('video');
        const videoSrc = !!videoData['url'] ? videoData['url'] : `https://vod.kaksya.in/${videoData?.['key'] || ''}`;
        if (!video) return;
        
        if (!Hls.isSupported()) {
            if (video.canPlayType('application/vnd.apple.mpegurl')) {
                video.src = videoSrc;
            }
            return;
        }

        const config = {
            startLevel: -1,
            startPosition: 0,
            loader: HLsLoader,
            // xhrSetup: function (xhr, url) {
            //     xhr.withCredentials = true; // do send cookie
            // },
            loaderConfig: {
                info: videoData['info'],
            }
        };

        const _hls = new Hls(config);
        _hls.currentLevel = -1;
        _hls.loadSource(videoSrc);
        _hls.attachMedia(video);
        handlePlayerUpdate(video);

        if (videoData['url']) {
            _hls.on(Hls.Events.MANIFEST_PARSED, function (eventName, data) {    
                if(!_hls.levels?.length) return;
                if(quality?.length) setQualities(['Auto', ..._hls.levels.map(levl => levl.height)]);
            });
        }
        
        // @ts-ignore
        window.hls = _hls;
    // @ts-ignore
    window.player = video;
        
    }, []);


    useEffect(() => {
        if(!window.hls || !videoData['url']) return;
        const qualityIndex = qualities?.findIndex(qty => qty === quality);
        if (qualityIndex >= 0)  window.hls.currentLevel = qualityIndex - 1;

    }, [quality]);

    

    return <div className={'w-full h-auto md:h-[80vh]'}>
        {(!videoData || !metaLoaded) && <div
            className={'absolute bg-opacity-40 bg-indigo-50 left-0 right-0 top-0 bottom-0 z-[20] flex flex-col items-center justify-center h-[100vh]'}>
        </div>}
        <video className="h-auto w-auto  md:h-[100vh] md:w-[100vw]" id="video" muted={false} />
    </div>
}
