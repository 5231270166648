import {

    List,
    Datagrid,
    TextField, NumberField, ReferenceField, TopToolbar, CreateButton, ExportButton, useRecordContext, BooleanField,
} from 'react-admin';
import FormattedDateTimeField from "../../../common/FormattedDateTimeField";
import { NoDataSection } from "../../../common/NoDataSection";
import { format } from 'date-fns';
import { downloadCSV, jsonToCSV } from '../../../common/CSVExport';
import MonthSelector from '../../../common/MonthSelector';
import { useState } from 'react';

const myCustomExporter = (records: any) => {
    const data = records.map((record: any) => {
        return {
            ...record,
            order_date: format(new Date(record.order_date), 'dd-MMM-yyyy hh:mm a'),
            // expiry_date: format(new Date(record.expiry_date), 'dd-MMM-yyyy hh:mm a'),
        };
    });
    const csv = jsonToCSV(data); // Convert the modified data to CSV format
    downloadCSV(csv, 'Store Items Report');  // Download the CSV
};


const ReportStoreItemsList = () => {
 const [selectedMonthRange, setSelectedMonthRange] = useState<{ firstDay: any, lastDay: any, month: any } | null>(null);
     const ListActions = () => (
         <TopToolbar className="">
             <MonthSelector monthsToShow={12} setSelectedMonthRange={setSelectedMonthRange} selectedMonthRange={selectedMonthRange} />
             <ExportButton />
         </TopToolbar>
     );

    return (

        <List
            actions={<ListActions />}
            filter={{
                order_date: {
                    format: 'hasura-raw-query',
                    value: {
                        _gt: selectedMonthRange?.firstDay,
                        _lt: selectedMonthRange?.lastDay
                    }
                },
            }}
            exporter={myCustomExporter}
            resource={'report_store_items'}
            empty={<NoDataSection resource={'report_store_items'} />}
        >
            <Datagrid>
                <TextField source="item_name" />
                <TextField source="item_type" />
                <TextField source="amount" />
                <TextField source="amount_paid" />
                <TextField source="order_by" />
                <TextField source="phone" />
                <TextField source="email" />
                <TextField source="address" />
                <TextField source="pincode" />
                <TextField source="awb_number" />
                <TextField source="payment_type" />
                <TextField source="order_id" />
                <FormattedDateTimeField source="order_date" />
                <TextField source="status" />
                {/* <TextField source="expiry_date" /> */}
                {/* <FormattedDateTimeField source="expiry_date" />  */}
            </Datagrid>
        </List>
    );
};
export default ReportStoreItemsList;
