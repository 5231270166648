import { Create, TextInput, NullableBooleanInput, SimpleForm, minValue,} from 'react-admin';
import ImageInput from "../../common/inputs/ImageInput";


const BannerCreate = () => (
    <Create redirect={'/banners'}>
        <SimpleForm >
            <ImageInput source="image" fullWidth />
            <NullableBooleanInput source="hidden" fullWidth label={'Hidden'} />
            <TextInput type={'number'} source="display_order" fullWidth validate={[minValue(0)]} />
            <TextInput source="redirect_url" fullWidth />
        </SimpleForm>
    </Create>
);

export default BannerCreate;
