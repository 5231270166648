import * as React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  useTranslate,
  Show,
  useRecordContext,
  required,
  ReferenceInput,
  SelectInput,
  DateTimeInput,
  SimpleShowLayout,
  useDataProvider,
} from "react-admin";
import { Questions, Tests } from "../../custom";
import { useParams } from "react-router";
import { LoadingSection } from "../../common/LoadingSection";
import { Divider, Typography } from "@mui/material";
import { useQuery } from "react-query";
import { useMemo, useState } from "react";
import Button from "@mui/material/Button";
import { ChevronLeft, ChevronRight, Print } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import section_question_mappings from "../questions_bank";

const QuestionPaper = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const navigate = useNavigate();
  const [showSolutions, setShowSolutions] = useState(false);
  const { data: sectionsData } = useQuery(
    ["sections_" + record.id, "getList", { test_id: record.id }],
    () =>
      dataProvider.getList("sections", {
        filter: { test_id: record.id },
        pagination: { page: 1, perPage: 200 },
        sort: { field: "display_order", order: "ASC" },
      })
  );

  const {
    data: sectionQuestionMappings,
    refetch: re,
    isLoading: iL,
    error: err,
  } = useQuery(
    ["section_question_mappings" + record.id, "getList", { test_id: record.id }],
    () =>
      dataProvider.getList("section_question_mappings", {
        filter: { test_id: record.id },

        pagination: { page: 1, perPage: 200 },
        sort: { field: "display_order", order: "ASC" },
      })
  );


  const {
    data: questionsData,
    refetch,
    isLoading,
    error,
  } = useQuery(
    ["questions_" + record.id, "getList", { test_id: record.id }],
    () =>
      dataProvider.getList("questions", {
        filter: {
          section_mapping: {
            format: "hasura-raw-query",
            value: {
              test_id: { _eq: record.id },
            },
          },
        },
        pagination: { page: 1, perPage: 200 },
        sort: { field: "display_order", order: "ASC" },
      })
  );

  const { data: optionsData } = useQuery(
    ["options_" + record.id, "getList", { test_id: record.id }],
    () =>
      dataProvider.getList("options", {
        filter: {
          question: {
            format: "hasura-raw-query",
            value: {
              test_id: { _eq: record.id },
            },
          },
        },
        pagination: { page: 1, perPage: 1000 },
        sort: { field: "display_order", order: "ASC" },
      })
  );

  const sections = useMemo(() => {
    if (
      questionsData?.data &&
      optionsData?.data &&
      sectionsData?.data &&
      sectionQuestionMappings?.data
    ) {
      const s = sectionsData?.data.map((sD) => {
        const relatedQuestions = questionsData.data
          .map((qd) => {
            return {
              ...qd,
              options: optionsData?.data?.filter(
                (oD) => oD?.question_id === qd?.id
              ),
            };
          })
          .filter((qD) =>
            sectionQuestionMappings?.data.some(
              (sqm) => sqm.section_id === sD.id && sqm.question_id === qD.id
            )
          );
  
        return {
          ...sD,
          questions: relatedQuestions,
        };
      });
  
      let index = 1;
      return s.map((res) => {
        res.questions.forEach((qq:any) => {
          qq.index = index;
          index++;
        });
        return res;
      });
    }
    return [];
  }, [questionsData, sectionsData, optionsData, sectionQuestionMappings]);
  
  return (
    <div>
      <div
        style={{
          textAlign: "center",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Button onClick={() => navigate(-1)}>
          <ChevronLeft /> Back
        </Button>
        <Button onClick={() => setShowSolutions(!showSolutions)}>
          Toggle Solutions
        </Button>

        <Button
          onClick={() => {
            // @ts-ignore
            const printContents = document.getElementById("question-view").innerHTML;
            const originalContents = document.body.innerHTML;
            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
          }}
        >
          Print <Print />
        </Button>
      </div>
      <div id={"question-view"}>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography variant={"h5"}>{record.name}</Typography>
        </div>
        <div
          style={{
            textAlign: "center",
            marginBottom: "10px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div style={{ padding: "5px 10px" }}>
            <Typography>
              <strong>{record.total_questions}</strong> Questions
            </Typography>
          </div>
          <div style={{ padding: "5px 10px" }}>
            <Typography>
              <strong>{record.total_marks}</strong> Marks
            </Typography>
          </div>
          <div style={{ padding: "5px 10px" }}>
            <Typography>
              <strong>{record.max_duration_in_minutes}</strong> Minutes
            </Typography>
          </div>
        </div>
        <Divider />
        <div>
          <div
            style={{
              background: "rgba(0,0,0,0.2)",
              padding: "2px 10px",
              marginBottom: "10px",
            }}
          >
            <Typography variant={"h6"}>General Instructions</Typography>
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: record.general_instructions }}
          />
        </div>
        <Divider />
        <div>
          <div
            style={{
              background: "rgba(0,0,0,0.2)",
              padding: "2px 10px",
              marginBottom: "10px",
            }}
          >
            <Typography variant={"h6"}>Test Instructions</Typography>
          </div>
          <div dangerouslySetInnerHTML={{ __html: record.test_instructions }} />
        </div>
        <div>
          {sections.map((section, index) => {
            return (
              <div key={index} style={{ marginBottom: "100px" }}>
                <div
                  style={{
                    background: "rgba(0,0,0,0.2)",
                    padding: "2px 10px",
                    marginBottom: "10px",
                  }}
                >
                  <Typography variant={"h6"}>{section.name}</Typography>
                </div>
                {section.questions.map(
                  (question: Questions & { index: number }, qIndex: number) => {
                    return (
                      <div
                        key={qIndex}
                        style={{
                          marginBottom: "50px",
                          display: "flex",
                          alignItems: "start",
                        }}
                      >
                        <div
                          style={{
                            background: "rgba(0,0,0,0.2)",
                            padding: "2px 10px",
                            marginBottom: "10px",
                          }}
                        >
                          Q. {question.index}
                        </div>
                        <div style={{ flex: "1" }}>
                          {question.image && (
                            <div
                              style={{
                                display: "flex",
                              }}
                            >
                              <img
                                style={{ maxHeight: "300px", maxWidth: "100%" }}
                                src={question.image}
                              />
                            </div>
                          )}
                          {question.content && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: question.content,
                              }}
                            />
                          )}
                          {question.solution_text && (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: question.solution_text,
                              }}
                            />
                          )}

                          <div>
                            {question.options.map((option, oIndex) => {
                              return (
                                <div
                                  key={oIndex}
                                  style={{
                                    width: "50%",
                                    padding: "10px",
                                    display: "inline-block",
                                  }}
                                >
                                  <div
                                    style={{
                                      padding: "10px",
                                      borderRadius: "10px",
                                      border: "1px solid rgba(0,0,0,0.2)",
                                      backgroundColor:
                                        showSolutions && option.is_correct
                                          ? "green"
                                          : "white",
                                      color:
                                        showSolutions && option.is_correct
                                          ? "white"
                                          : "black",
                                    }}
                                  >
                                    {option.image && (
                                      <div
                                        style={{
                                          display: "flex",
                                        }}
                                      >
                                        <img
                                          style={{
                                            maxHeight: "100px",
                                            maxWidth: "100%",
                                          }}
                                          src={option.image}
                                        />
                                      </div>
                                    )}
                                    {option.content && (
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: option.content,
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              );
                            })}
                          </div>

                          {showSolutions && (
                            <>
                              {question?.solution_description && (
                                <>
                                  <div
                                    style={{
                                      background: "rgba(0,0,0,0.2)",
                                      padding: "2px 10px",
                                      marginBottom: "10px",
                                      height: "30px"
                                    }}
                                  >
                                    Solution Description :-
                                  </div>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: question.solution_description,
                                    }}
                                  />
                                </>
                              )}
                              {question?.solution_image && (
                                <div style={{ display: "flex" }}>
                                  <div
                                    style={{
                                      background: "rgba(0,0,0,0.2)",
                                      padding: "2px 10px",
                                      marginBottom: "10px",
                                      height: "30px"
                                    }}
                                  >
                                    Solution Image :-
                                  </div>
                                  <img
                                    style={{
                                      maxHeight: "300px",
                                      maxWidth: "100%",
                                    }}
                                    src={question.solution_image}
                                    alt="Solution Illustration"
                                  />
                                </div>
                              )}
                            </>
                          )}
                        </div>
                        <div
                          style={{
                            background: "rgba(0,0,0,0.2)",
                            padding: "2px 10px",
                            marginBottom: "10px",
                          }}
                        >
                          Marks: +{question.positive_marks || 0} | -
                          {question.negative_marks || 0}
                        </div>
                      </div>
                    );
                  }
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
const TestShow = () => {
  let { programId, testSeriesId, testId } = useParams();
  if (!testId) {
    return <LoadingSection />;
  }
  return (
    <Show title={<TestTitle />} resource={"tests"} id={testId}>
      <SimpleShowLayout>
        <div style={{ width: "900px", margin: "auto" }}>
          <QuestionPaper />
        </div>
      </SimpleShowLayout>
    </Show>
  );
};

const TestTitle = () => {
  const record = useRecordContext<Tests>();
  const translate = useTranslate();

  return record ? (
    <span>
      {translate("resources.tests.name", { smart_count: 1 })} &quot;
      {record.name}&quot;
    </span>
  ) : null;
};

export default TestShow;
