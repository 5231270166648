// This application is in context of kaksya.in
import * as React from 'react';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import { Route } from 'react-router';
import './styles.css';
import authProvider from './authProvider';
import { Login, Layout } from './layout';
import englishMessages from './i18n/en';
import { lightTheme } from './layout/themes';
import Configuration from './configuration/Configuration';
import organizations from "./resources/organizations";
import { useEffect, useState } from "react";
import buildHasuraProvider from './dataProviders'
import client from "./harua-client";
import { CircularProgress } from "@mui/material";
import users from "./resources/users";
import banners from "./resources/banners";
import programs from "./resources/programs";
import categories from "./resources/categories";
import SubjectCreate from "./resources/subjects/SubjectCreate";
import SubjectList from "./resources/subjects/SubjectList";
import SubjectEdit from "./resources/subjects/SubjectEdit";
import ChapterList from "./resources/chapters/ChapterList";
import ChapterCreate from "./resources/chapters/ChapterCreate";
import ChapterEdit from "./resources/chapters/ChapterEdit";
import TopicList from "./resources/topics/TopicList";
import TopicCreate from "./resources/topics/TopicCreate";
import TopicEdit from "./resources/topics/TopicEdit";
import ContentList from "./resources/contents/ContentList";
import ContentCreate from "./resources/contents/ContentCreate";
import ContentEdit from "./resources/contents/ContentEdit";
import batch_templates from "./resources/batch_templates";
import batches from "./resources/batches";
import issue_templates from "./resources/issue_templates";
import issue_topics from "./resources/issue_topics";
import blogs from "./resources/blogs";
import coupons from "./resources/coupons";
import { Dashboard } from "./resources/dashboard";
import ProductTemplateList from "./resources/product_templates/ProductTemplateList";
import ProductTemplatesCreate from "./resources/product_templates/ProductTemplatesCreate";
import ProductTemplateEdit from "./resources/product_templates/ProductTemplateEdit";
import ProductList from "./resources/products/ProductList";
import ProductCreate from "./resources/products/ProductCreate";
import ProductEdit from "./resources/products/ProductEdit";
import AttributeValueList from "./resources/attribute_values/AttributeValueList";
import AttributeValueCreate from "./resources/attribute_values/AttributeValueCreate";
import AttributeValueEdit from "./resources/attribute_values/AttributeValueEdit";
import AttributeList from "./resources/attributes/AttributeList";
import AttributeCreate from "./resources/attributes/AttributeCreate";
import AttributeEdit from "./resources/attributes/AttributeEdit";
import TestSeriesList from "./resources/test_serieses/TestSeriesList";
import TestSeriesCreate from "./resources/test_serieses/TestSeriesCreate";
import TestSeriesEdit from "./resources/test_serieses/TestSeriesEdit";
import TestList from "./resources/tests/TestList";
import TestCreate from "./resources/tests/TestCreate";
import TestEdit from "./resources/tests/TestEdit";
import SectionList from "./resources/sections/SectionList";
import SectionCreate from "./resources/sections/SectionCreate";
import SectionEdit from "./resources/sections/SectionEdit";
import QuestionList from "./resources/questions/QuestionList";
import QuestionCreate from "./resources/questions/QuestionCreate";
import QuestionEdit from "./resources/questions/QuestionEdit";
import otps from "./resources/otps";
import app_versions from "./resources/app_versions";
import testimonials from './resources/testimonials';
import faqs from "./resources/faqs";
import doubts from "./resources/doubts";
import TestSeriesShow from "./resources/test_serieses/TestSeriesShow";
import blog_topics from "./resources/blog_topics";
import StudentResponseList from "./resources/student_responses/StudentResponseList";
import StudentResponseShow from "./resources/student_responses/StudentResponseShow";
import TestShow from "./resources/tests/TestShow";
import organization_resources from './resources/organization_resources';
import video_subjects from "./resources/video_subjects";
import videos from "./resources/videos";
import batch_schedules from "./resources/batch_schedules";
import download_categories from "./resources/download_categories";
import downloads from "./resources/downloads";
import podcast_topics from "./resources/podcast_topics";
import podcasts from './resources/podcasts';
import StudentResponseEvaluate from './resources/student_responses/StudentResponseEvaluate';
import orders from "./resources/orders";
import Store from "./resources/store/Store";
import VideoBulkUpload from "./resources/videos/VideoBulkUpload";
import student_video_stats from './resources/student_video_stats';
import product_templates from "./resources/product_templates";
import ReportUserSignupsList from './resources/reports/report_user_signups/ReportUserSignupsList';
import ReportTestsList from './resources/reports/report_tests/ReportTestsList';
import ReportStoreItemsList from './resources/reports/report_store_items/ReportStoreItemsList';
import ReportOrdersList from './resources/reports/report_orders/ReportOrdersList';
import ReportBatchStudentList from './resources/reports/report_batch_students/ReportBatchStudentsList';
import ReportApplicationUsersList from './resources/reports/report_application_users/ReportApplicationUsersList';
import SubjectShow from './resources/subjects/SubjectShow';
import ChapterShow from './resources/chapters/ChapterShow';
import queries from './resources/queries';
import ExcerciseShow from './resources/batches/BatchesQuestionShow';
import TopicQuestionsList from './resources/contents/TopicQuestionsList';
import TopicQuestionsEdit from './resources/contents/TopicQuestionsEdit';
import TopicQuestionsCreate from './resources/contents/TopicQuestionsCreate';
import BatchesQuestionList from './resources/batches/BatchesQuestionList';
import BatchesQuestionCreate from './resources/batches/BatchesQuestionCreate';
import BatchesQuestionEdit from './resources/batches/BatchesQuestionEdit';
import BatchesQuestionShow from './resources/batches/BatchesQuestionShow';
import non_category_products from "./resources/non_category_products";
import NonCategoryProductCreate from "./resources/non_category_products/NonCategoryProductCreate";
import NonCategoryProductEdit from "./resources/non_category_products/NonCategoryProductEdit";
import demo_videos_categories from './resources/demo_videos_categories';
import DemoVideosList from './resources/demo_videos/DemoVideosList';
import DemoVideoCreate from './resources/demo_videos/DemoVideoCreate';
import DemoVideoEdit from './resources/demo_videos/DemoVIdeoEdit';
import issues from "./resources/issues";
import deleted_users from "./resources/deleted_users";
import BatchSchedulePreview from './resources/batch_schedules/BatchSchedulePreview';
import segmentations from "./resources/segmentations";
import campaigns from "./resources/campaigns";
import { BrowserRouter as Router } from 'react-router-dom';
import CommentsList from './resources/comments/CommentsList';
import section_question_mappings from './resources/questions_bank';
import ProductShow from './resources/products/ProductShow';
import QuestionBankList from './resources/questions_bank/QuestionBankList';

const i18nProvider = polyglotI18nProvider(locale => {
       if (locale === 'fr') {
              return import('./i18n/fr').then(messages => messages.default);
       }
       if (locale === 'hi') {
              return import('./i18n/hi').then(messages => messages.default);
       }

       // Always fallback on english
       return englishMessages;
}, 'en');

const App = () => {
       const [dataProvider, setDataProvider] = useState(null as any)

       useEffect(() => {
              const buildDataProvider = async () => {
                     const dataProvider = await buildHasuraProvider({ client })
                     setDataProvider(() => dataProvider)
              }
              buildDataProvider().then(() => { })
       }, [])
       if (!dataProvider) return <CircularProgress />

       return <Admin
              title=""
              dataProvider={dataProvider}
              authProvider={authProvider}
              loginPage={Login}
              layout={Layout}
              dashboard={Dashboard}
              i18nProvider={i18nProvider}
              disableTelemetry
              theme={lightTheme}
       >
              <CustomRoutes>
                     <Route path="/store" element={<Store />} />
                     <Route path="/videos/bulk" element={<VideoBulkUpload />} />
                     <Route path="/configuration" element={<Configuration />} />
                     {/*Content Routes*/}
                     <Route path="/programs/:programId/subjects" element={<SubjectList />} />
                     <Route path="/programs/:programId/subjects/create" element={<SubjectCreate />} />
                     <Route path="/programs/:programId/subjects/:subjectId/edit" element={<SubjectEdit />} />
                     <Route path="/programs/:programId/subjects/:subjectId/show" element={<SubjectShow />} />


                     <Route path="/programs/:programId/test_serieses" element={<TestSeriesList />} />
                     <Route path="/programs/:programId/test_serieses/create" element={<TestSeriesCreate />} />
                     <Route path="/programs/:programId/test_serieses/:testSeriesId/edit" element={<TestSeriesEdit />} />
                     <Route path="/programs/:programId/test_serieses/:testSeriesId/show" element={<TestSeriesShow />} />


                     <Route path="/programs/:programId/test_serieses/:testSeriesId/tests" element={<TestList />} />
                     <Route path="/programs/:programId/test_serieses/:testSeriesId/tests/create" element={<TestCreate />} />
                     <Route path="/programs/:programId/test_serieses/:testSeriesId/tests/:testId/edit" element={<TestEdit />} />
                     <Route path="/programs/:programId/test_serieses/:testSeriesId/tests/:testId/question_paper" element={<TestShow />} />
                     <Route path="/programs/:programId/exercise/:testSeriesId/tests/:testId/question_paper" element={<TestShow />} />
                     <Route path="/programs/:programId/exercise/:exerciseId/tests/:testId/questions_list" element={<TopicQuestionsList />} />
                     <Route path="/programs/:programId/exercise/:exerciseId/tests/:testId/questions/create" element={<TopicQuestionsCreate />} />
                     <Route path="/programs/:programId/exercise/:exerciseId/tests/:testId/questions/:questionId/edit" element={<TopicQuestionsEdit />} />


                     {/* Batches */}
                     <Route path="/batches/:batchId/programs/:programId/exercise/:exerciseId/tests/:testId/questions_list" element={<BatchesQuestionList />} />
                     <Route path="/batches/:batchId/programs/:programId/exercise/:exerciseId/tests/:testId/questions/create" element={<BatchesQuestionCreate />} />
                     <Route path="/batches/:batchId/programs/:programId/exercise/:exerciseId/tests/:testId/questions/:questionId/edit" element={<BatchesQuestionEdit />} />
                     <Route path="/batches/:batchId/programs/:programId/exercise/:exerciseId/tests/:testId/question_paper" element={<BatchesQuestionShow />} />


                     <Route path="/programs/:programId/test_serieses/:testSeriesId/tests/:testId/sections"
                            element={<SectionList />} />
                     <Route path="/programs/:programId/test_serieses/:testSeriesId/tests/:testId/sections/create"
                            element={<SectionCreate />} />
                     <Route path="/programs/:programId/test_serieses/:testSeriesId/tests/:testId/sections/:sectionId/edit"
                            element={<SectionEdit />} />

                     <Route path="/programs/:programId/test_serieses/:testSeriesId/tests/:testId/student_responses"
                            element={<StudentResponseList />} />
                     <Route path="/programs/:programId/test_serieses/:testSeriesId/tests/:testId/student_responses/:studentResponseId/show"
                            element={<StudentResponseShow />} />
                     <Route path="/programs/:programId/test_serieses/:testSeriesId/tests/:testId/student_responses/:studentResponseId/evaluate"
                            element={<StudentResponseEvaluate />} />


                     <Route path="/programs/:programId/test_serieses/:testSeriesId/tests/:testId/sections/:sectionId/questions"
                            element={<QuestionList />} />
                     <Route
                            path="/programs/:programId/test_serieses/:testSeriesId/tests/:testId/sections/:sectionId/questions/create"
                            element={<QuestionCreate />} />
                     <Route
                            path="/programs/:programId/test_serieses/:testSeriesId/tests/:testId/sections/:sectionId/questions/:questionId/edit"
                            element={<QuestionEdit />} />


                     <Route path="/programs/:programId/subjects/:subjectId/chapters" element={<ChapterList />} />
                     <Route path="/programs/:programId/subjects/:subjectId/chapters/create" element={<ChapterCreate />} />
                     <Route path="/programs/:programId/subjects/:subjectId/chapters/:chapterId/edit" element={<ChapterEdit />} />
                     <Route path="/programs/:programId/subjects/:subjectId/chapters/:chapterId/show" element={<ChapterShow />} />


                     <Route path="/programs/:programId/subjects/:subjectId/chapters/:chapterId/topics" element={<TopicList />} />
                     <Route path="/programs/:programId/subjects/:subjectId/chapters/:chapterId/topics/create"
                            element={<TopicCreate />} />
                     <Route path="/programs/:programId/subjects/:subjectId/chapters/:chapterId/topics/:topicId/edit"
                            element={<TopicEdit />} />
                     <Route path="/programs/:programId/subjects/:subjectId/chapters/:chapterId/topics/:topicId/contents"
                            element={<ContentList />} />
                     <Route path="/programs/:programId/subjects/:subjectId/chapters/:chapterId/topics/:topicId/contents/create"
                            element={<ContentCreate />} />
                     <Route
                            path="/programs/:programId/subjects/:subjectId/chapters/:chapterId/topics/:topicId/contents/:contentId/edit"
                            element={<ContentEdit />} />

                     {/* Demo Videos */}
                     <Route path="/demo_video_categories/:categoryId/demo_video" element={<DemoVideosList />} />
                     <Route path="/demo_video_categories/:categoryId/demo_video/create" element={<DemoVideoCreate />} />
                     <Route path="/demo_video_categories/:categoryId/demo_video/:demoVideoId/edit" element={<DemoVideoEdit />} />



                     {/*Ecom Routes*/}
                     <Route path="/ecom_categories/:categoryId/ecom_product_templates" element={<ProductTemplateList />} />
                     <Route path="/ecom_categories/:categoryId/ecom_product_templates/create"
                            element={<ProductTemplatesCreate />} />
                     <Route path="/ecom_categories/:categoryId/ecom_product_templates/:productTemplateId/edit"
                            element={<ProductTemplateEdit />} />
                     {/*Products*/}
                     <Route path="/ecom_categories/:categoryId/ecom_product_templates/:productTemplateId/ecom_products" element={<ProductList />} />
                     <Route path="/ecom_categories/:categoryId/ecom_product_templates/:productTemplateId/ecom_products/create"
                            element={<ProductCreate />} />
                     <Route path="/ecom_categories/:categoryId/ecom_product_templates/:productTemplateId/ecom_products/:productId/edit"
                            element={<ProductEdit />} />
                     <Route path="/ecom_categories/:categoryId/ecom_product_templates/:productTemplateId/ecom_products/:productId/show"
                            element={<ProductShow />} />
                     {/*Attributes*/}
                     <Route path="/ecom_categories/:categoryId/ecom_attributes" element={<AttributeList />} />
                     <Route path="/ecom_categories/:categoryId/ecom_attributes/create"
                            element={<AttributeCreate />} />
                     <Route path="/ecom_categories/:categoryId/ecom_attributes/:attributeId/edit"
                            element={<AttributeEdit />} />
                     {/*Attribute Values*/}
                     <Route path="/ecom_categories/:categoryId/ecom_attributes/:attributeId/ecom_attribute_values"
                            element={<AttributeValueList />} />
                     <Route path="/ecom_categories/:categoryId/ecom_attributes/:attributeId/ecom_attribute_values/create"
                            element={<AttributeValueCreate />} />
                     <Route
                            path="/ecom_categories/:categoryId/ecom_attributes/:attributeId/ecom_attribute_values/:attributeValueId/edit"
                            element={<AttributeValueEdit />} />

                     <Route
                            path="/report/signups"
                            element={<ReportUserSignupsList />} />
                     <Route
                            path="/report/tests"
                            element={<ReportTestsList />} />
                     <Route
                            path="/report/store_items"
                            element={<ReportStoreItemsList />} />
                     <Route
                            path="/report/orders"
                            element={<ReportOrdersList />} />
                     <Route
                            path="/report/batch_students"
                            element={<ReportBatchStudentList />} />
                     <Route
                            path="/report/application_users"
                            element={<ReportApplicationUsersList />} />
                     <Route path="/ecom_product_templates/:productTemplateId/ecom_products/create"
                            element={<NonCategoryProductCreate />} />
                     <Route path="/ecom_product_templates/:productTemplateId/ecom_products/:productId/edit"
                            element={<NonCategoryProductEdit />} />

                     <Route path="/batch_schedules/:scheduleId/preview"
                            element={<BatchSchedulePreview />} />

                     <Route path="/comments/:parentId/:parentType"
                            element={<CommentsList />} />

                     {/* ---------------------- questions bank------------------- */}
                     <Route path="/questions_bank"
                            element={<QuestionBankList />} />
              </CustomRoutes>


              {/*<Resource name="acl_user_permissions" {...user_permissions} />*/}
              {/*<Resource name="ecom_product_templates" {...product_templates} />*/}
              <Resource name="ecom_products" {...non_category_products} />
              <Resource name="segmentations" {...segmentations} />
              <Resource name="campaigns" {...campaigns} />
              <Resource name="deleted_users" {...deleted_users} />
              <Resource name="organizations" {...organizations} />
              <Resource name="ecom_orders" {...orders} />
              <Resource name="podcast_topics" {...podcast_topics} />
              <Resource name="podcasts" {...podcasts} />
              <Resource name="downloads" {...downloads} />
              <Resource name="download_categories" {...download_categories} />
              <Resource name="student_video_stats" {...student_video_stats} />
              <Resource name="batch_schedules" {...batch_schedules} />
              <Resource name="organization_resources" {...organization_resources} />
              <Resource name="ce_blog_topics" {...blog_topics} />
              <Resource name="faqs" {...faqs} />
              <Resource name="doubts" {...doubts} />
              <Resource name="queries" {...queries} />
              <Resource name="testimonials" {...testimonials} />
              <Resource name="otps" {...otps} />
              <Resource name="app_versions" {...app_versions} />
              <Resource name="batch_templates" {...batch_templates} />
              <Resource name="batches" {...batches} />
              <Resource name="programs" {...programs} />
              <Resource name="users" {...users} />
              <Resource name="banners" {...banners} />
              <Resource name="ecom_coupons" {...coupons} />
              <Resource name="ecom_categories" {...categories} />
              <Resource name="cs_issue_templates" {...issue_templates} />
              <Resource name="cs_issue_topics" {...issue_topics} />
              <Resource name="cs_issues" {...issues} />
              <Resource name="ce_blogs" {...blogs} />
              <Resource name="video_subjects" {...video_subjects} />
              <Resource name="videos" {...videos} />
              <Resource name="demo_video_categories" {...demo_videos_categories} />
       </Admin>
};

export default App;
