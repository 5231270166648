import React from "react";
import { useQuery } from "react-query";
import { useDataProvider } from "react-admin";
import { LoadingSection } from "../../common/LoadingSection";
import { NoDataSection } from "../../common/NoDataSection";
import { formatDistanceToNow } from "date-fns";

interface UserDetailProps {
    userId: string;
    createdAt: string;
}
const formatRelativeTime = (dateString: string) => {
    const date = new Date(dateString);
    return `${formatDistanceToNow(date, { addSuffix: true })}`;
  };

const UserDetail: React.FC<UserDetailProps> = ({ userId, createdAt }) => {
    const dataProvider = useDataProvider();

    // Fetch user details using react-query
    const { data: user, isLoading, error } = useQuery(
        ["user", userId],
        () =>
            dataProvider.getOne("users", {
                id: userId,
            }),
        {
            enabled: !!userId, // Ensure query runs only if userId is provided
        }
    );

    if (isLoading) return <LoadingSection />;
    if (error || !user) return <div>User not found!</div>;

    const userData = user?.data;

    // Generate short name from user's first and last name
    const generateShortName = (firstName: string, lastName: string) => {
        const shortName =
            (firstName?.charAt(0) || "") + (lastName?.charAt(0) || "");
        return shortName.toUpperCase();
    };

    const shortName = generateShortName(
        userData?.first_name || "",
        userData?.last_name || ""
    );

    return (
        <div className="flex items-center gap-4">
            {/* User avatar or short name */}
            {userData?.profile_image ? (
                <img
                    src={userData.profile_image}
                    alt={`${userData.first_name} ${userData.last_name}`}
                    className="w-12 h-12 rounded-full"
                />
            ) : (
                <div className="w-12 h-12 flex items-center justify-center rounded-full bg-gray-300 text-white font-bold text-lg">
                    {shortName}
                </div>
            )}

            {/* User details */}
            <div>
                <p className="text-md">
                    {userData?.first_name} {userData?.last_name}
                </p>
                <p className="text-sm text-gray-500">
                {formatRelativeTime(createdAt)}
                </p>
            </div>
        </div>
    );
};

export default UserDetail;
