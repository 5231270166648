import {Link, FieldProps, useRecordContext, ReferenceManyCount} from 'react-admin';
import { Ecom_Attributes, Ecom_Categories,} from "../../custom";

const AttributeValuesLinkField = (props: FieldProps<Ecom_Categories>) => {
    const record = useRecordContext<Ecom_Attributes>();
    if (!record) {
        return null;
    }
    return (
        <Link to={`/ecom_categories/${record.category_id}/ecom_attributes/${record.id}/ecom_attribute_values`}>
            Attribute Values
            (
            <ReferenceManyCount
                label="Attribute Values"
                reference="ecom_attribute_values"
                target="attribute_id"
            />
            )
        </Link>
    );
};

AttributeValuesLinkField.defaultProps = {
    label: 'Attribute Values'
};

export default AttributeValuesLinkField;
