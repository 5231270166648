import * as React from 'react';
import {

    List,
    Datagrid,
    TextField,
    DateField,
    TopToolbar,
    ReferenceField,
    CreateButton,
    ExportButton, useRecordContext,
} from 'react-admin';
import ThumbnailField from "../../common/ThumbnailField";
import { useNavigate } from "react-router-dom";
import { LoadingSection } from "../../common/LoadingSection";
import { useParams } from "react-router";
import Button from "@mui/material/Button";
import { CopyAll, Edit } from "@mui/icons-material";
import { Sections } from "../../custom";
import programs from "../programs";
import QuestionsLinkField from "./QuestionsLinkField";
import { NoDataSection } from "../../common/NoDataSection";
import FormattedDateTimeField from "../../common/FormattedDateTimeField";
import { DeleteWithDialogAction } from "../../common/DeleteWithDialogAction";
import { CustomDelete } from '../../common/CustomDelete';
import { BulkUploadSectionQuestions } from './SectionQuestionUpload';

const ListActions = ({
    programId,
    testSeriesId,
    testId
}: { programId: string, testId: string, testSeriesId: string }) => {
    const navigate = useNavigate();
    return <TopToolbar>
        <CreateButton onClick={(e) => {
            e.preventDefault();
            navigate(`/programs/${programId}/test_serieses/${testSeriesId}/tests/${testId}/sections/create`)
        }} />
        <ExportButton />
    </TopToolbar>
};

const EditButton = () => {
    const navigate = useNavigate();
    const record: Sections = useRecordContext<Sections>();
    const { testSeriesId, programId, testId } = useParams();

    return <Button startIcon={<Edit />} onClick={(e) => {
        navigate(`/programs/${programId}/test_serieses/${testSeriesId}/tests/${testId}/sections/${record.id}/edit`)
    }}>
        Edit
    </Button>

};

export const CopyIDButton = () => {
    const record = useRecordContext();
    return <Button startIcon={<CopyAll />} onClick={(e) => {
        navigator.clipboard.writeText(record.id as string);
    }}>
        Copy ID
    </Button>

};

const SectionList = () => {
    const record = useRecordContext()
    const navigate = useNavigate();
    const { testSeriesId, programId, testId } = useParams();
    if (!testId) {
        return <LoadingSection />
    }
    return (
        <List
            empty={<NoDataSection resource={'sections'} onCreate={() => {
                navigate(`/programs/${programId}/test_serieses/${testSeriesId}/tests/${testId}/sections/create`)
            }} />}
            sort={{ field: 'created_at', order: 'DESC' }} resource={'sections'} actions={
                <ListActions programId={programId || ''} testSeriesId={testSeriesId || ''} testId={testId || ''} />
            } filter={{
                test_id: testId,
            }}>
            <Datagrid>
                <CopyIDButton />
                <TextField source="name" />
                <ReferenceField reference={'tests'} source={'test_id'}>
                    <TextField source="name" />
                </ReferenceField>
                <FormattedDateTimeField source="created_at" />

                <QuestionsLinkField />
                <EditButton />
               < BulkUploadSectionQuestions/>
                {/* <DeleteWithDialogAction/> */}
                <CustomDelete />
            </Datagrid>
        </List>
    );
};
export default SectionList;
