import React, { useEffect, useState } from "react";
import Header from "./Header";
import Footer, { QualityControl } from "./Footer";
import cn from "clsx";
import { Play, Redo, Undo } from "lucide-react";
import YoutubePlayer from "./Players/YoutubePlayer";
import PlayerCore from "./PlayerCore";
import PlayPauseAction from "./actions/PlayPauseAction";
import { usePlayerControls } from "../../context/PlayerControlsContext";
import { usePlayerSchedule } from "../../context/PlayerScheduleContext";
import Loading from "./ui/Loading";

export default function VideoPlayer({ hideControls }) {
    const { handlePlayerPausedState, initialPaused, loading, paused } = usePlayerControls();
    const { handleRewind, handleForward } = usePlayerControls();
    const { videoData } = usePlayerSchedule();

    return <div className={'w-full h-full flex flex-col bg-black'}>
        <div className={'w-full  overflow-hidden bg-black relative'}>
            {
                videoData?.videoType == "YOUTUBE" ? <YoutubePlayer url={videoData?.url} /> :
                    <>
                        {videoData?.videoType === "SECURE" || videoData?.videoType === "SECURE_V2" ? (
                            <PlayerCore />
                        ) :
                            null
                        }
                    </>
            }
            {
                <div
                    className={cn(' absolute opacity-0 transition-all duration-200 left-0 right-0 bg-center bg-contain bg-no-repeat top-0 bottom-0 z-[12] flex flex-col items-center justify-center', {
                        ['opacity-100']: (initialPaused || (paused)),
                        [' bg-black']: videoData?.['youtube'],
                    })}
                    style={{ backgroundImage:  '' }}
                >
                    <div className={'absolute bg-red top-0 left-0 right-0 bottom-0 h-full'}
                        onClick={() => handlePlayerPausedState()} />
                    <div
                        className={cn('p-6  cursor-pointer transition-all duration-200  rounded-full bg-indigo-500 hidden md:flex ', {})}>
                        <Play fill={'fill'} className={'w-10 h-10 fill-white text-white'} />
                    </div>
                    {
                        paused && <div className={'flex md:hidden items-center gap-2 bg-app-blur px-3 py-3 rounded-md'}>
                            <Undo onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleRewind();
                            }} className={'player-icons'} />
                            <PlayPauseAction />
                            <Redo onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                handleForward();
                            }} className={'player-icons'} />
                        </div>
                    }
                </div>
            }
            {
                loading && <div
                    id={'player-mid'}
                    className={cn('absolute left-0 right-0  top-0 bottom-0 z-[20] flex flex-col items-center justify-center',)}
                    onClick={() => handlePlayerPausedState()}>
                    <div
                        className={cn('p-2  text-white cursor-pointer bg-black border border-gray-800 transition-all duration-200 bg-opacity-70 rounded-lg', {
                            'opacity-0': !loading,
                            'opacity-100': loading,
                        })}>
                        <Loading />
                    </div>
                </div>
            }
            <Header hideControls={hideControls} />
            <Footer hideControls={hideControls} />
        </div>
        <div className={'flex-1 p-2 md:hidden overflow-hidden'}>
            <div className={'h-full flex flex-col w-full rounded-md bg-white/10'}>
                <div className={' px-2 py-1.5 border-b flex justify-between gap-2 border-gray-700'}>
                    <h2 className={'text-white'}>
                        {videoData?.title}
                    </h2>
                    <QualityControl />
                </div>
            </div>
        </div>
    </div>
}
