import { useNavigate } from "react-router";
import ImageEye from '@mui/icons-material/RemoveRedEye';
import { useRecordContext } from "react-admin";

export const ShowCommentsButton = ({ parentType }: { parentType: string }) => {
    const record =useRecordContext()
    const navigate = useNavigate();
    return <div className="text-blue-800 hover:cursor-pointer hover:bg-gray-200 p-2" onClick={(e) => {
        navigate(`/comments/${record?.id}/${parentType}`)
    }}>
        <ImageEye /> Comments
    </div>
}