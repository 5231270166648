import Hls from "hls.js";

class HLsLoader extends Hls.DefaultConfig.loader {
  constructor(config) {
    super(config);
    const keyBuffer = config.loaderConfig?.info
    // Bind default Hls load method
    const load = this.load.bind(this);

    // Set custom Hls load method
    this.load = function (context, config, callbacks) {
      if (context.url.includes("/give-me-license/")) {
        const octetsPairs = keyBuffer.match(/[\da-f]{2}/gi);
        if (octetsPairs) {
          const onSuccess = callbacks.onSuccess;
          callbacks.onSuccess = function (response, stats, context) {
            response.data = new Uint8Array(octetsPairs.map((h) => parseInt(h, 16)));
            onSuccess(response, stats, context, null);
          };
          load(context, config, callbacks);
        }

      } else {
        load(context, config, callbacks);
      }

    }
  }
}

export default HLsLoader
