import { Pause, Play } from "lucide-react";
import { usePlayerControls } from "../../../context/PlayerControlsContext";

export default function PlayPauseAction() {
    const { paused, handlePlayerPausedState } = usePlayerControls();
    return <div onClick={handlePlayerPausedState}>
        {
            paused ? <Play className={'player-icons fill-white'} fill={'fill'} /> :
                <Pause className={'player-icons fill-white'} fill={'fill'} />
        }
    </div>
}
