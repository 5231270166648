import Button from "@mui/material/Button";
import { CloudUploadOutlined } from "@mui/icons-material";
import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Tooltip } from "@mui/material";
import BulkQuestionsInput from "../../common/inputs/BulkQuestionsInput";
import { Download } from "lucide-react";

function BulkUploadDialog({ open, setOpen }: { open: boolean, setOpen: any }) {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <div className="flex flex-row justify-between">

                <DialogTitle>
                    Bulk Upload Questions
                </DialogTitle>
                <DialogTitle>
                    <a
                        href="https://assets.kaksya.in/samples/question-upload.xlsx"
                        download
                        className="flex gap-2 text-blue-900 cursor-pointer hover:underline"
                    >
                        <Download /> Sample
                    </a>
                </DialogTitle>

            </div>
            <DialogContent>
                <DialogContentText>
                    <BulkQuestionsInput onSuccess={(key: string) => {
                        handleClose();
                    }} />
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
            </DialogActions>
        </Dialog>
    );
}


export const BulkUploadQuestionBankAction = ({ }: any) => {
    const [open, setOpen] = React.useState(false);
    return <>
        <BulkUploadDialog open={open} setOpen={setOpen} />
        <Tooltip title="Bulk Upload">
            <Button onClick={() => {
                setOpen(true)
            }}>
                <CloudUploadOutlined />
                <span className="px-2">

                    UPLOAD
                </span>
            </Button>
        </Tooltip>
    </>
};

